import {
    actionTypes
} from '../constants/actionTypes';

const initialState = {
    matchingCustomers: [],
    selectedCustomer: {
        name: '',
        street: '',
        houseNumber: '',
        houseNumberExtension: '',
        postalCode: '',
        city: '',
        apiId: '',
        commercialContact: {
            title: '',
            fullName: '',
            email: '',
            phoneNumber: '',
            birthdate: null,
            nameInitials: ''
        },
        technicalContact: {
            title: '',
            fullName: '',
            email: '',
            phoneNumber: ''
        },
        projectManager: {
            title: '',
            fullName: '',
            email: '',
            phoneNumber: ''
        },
        financialContact: {
            title: '',
            fullName: '',
            email: '',
            phoneNumber: '',
            street: '',
            houseNumber: '',
            houseNumberExtension: '',
            postalCode: '',
            city: ''
        },
        kvk: '',
        krn: '',
        manually: false,
        api_id: '',
        odidoBillingAccountCode: null,
        connectWiseId: null
    }
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.setCustomers:
            return {
                ...state,
                matchingCustomers: action.payload
            };
        case actionTypes.setSelectedCustomer:
            const customer = action.payload;
            // ensure that contacts are defined
            if (!customer.commercialContact) {
                customer.commercialContact = {};
            }
            if (!customer.technicalContact) {
                customer.technicalContact = {};
            }
            if (!customer.projectManager) {
                customer.projectManager = {};
            }
            if (!customer.financialContact) {
                customer.financialContact = {};
            }
            return {
                ...state,
                selectedCustomer: customer
            };
        case actionTypes.setCustomerName:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    name: action.payload
                }
            }
        case actionTypes.updateContactInfo:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    [action.payload.typeOfCustomer]: {
                        ...state.selectedCustomer[action.payload.typeOfCustomer],
                        [action.payload.propertyToChange]: action.payload.newValue,
                    }
                }
            }
        case actionTypes.updateTechnicalContact:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    [action.payload.typeOfCustomer]: {
                        ...state.selectedCustomer[action.payload.typeOfCustomer],
                        fullName: action.payload.newFullName,
                        email: action.payload.newEmail,
                        phoneNumber: action.payload.newPhoneNumber,
                        title: action.payload.newTitle,
                    },

                }
            }
        case actionTypes.updateProjectManagerContact:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    [action.payload.typeOfCustomer]: {
                        ...state.selectedCustomer[action.payload.typeOfCustomer],
                        fullName: action.payload.newFullName,
                        email: action.payload.newEmail,
                        phoneNumber: action.payload.newPhoneNumber,
                        title: action.payload.newTitle,
                    },

                }
            }
        case actionTypes.updateContactInfoAdditionalProperties:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    [action.payload.propertyToChange]: action.payload.newValue,
                }
            }
        case actionTypes.clearSelectedCustomer:
            return {
                ...state,
                selectedCustomer: initialState.selectedCustomer,
            };
        /**
         * When a new quotation is selected, we need to clear the current customer
         */
        case actionTypes.setQuotationId:
            return {
                ...state,
                selectedCustomer:{...initialState.selectedCustomer}
            }
        case actionTypes.setCustomerIrmaID:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    api_id: action.payload
                }
            }
        case actionTypes.setCustomerOdidoBillingAccountCode:
            return {
                ...state,
                selectedCustomer: {
                    ...state.selectedCustomer,
                    odidoBillingAccountCode: action.payload
                }
            }
        default:
            return state;
    }
}
