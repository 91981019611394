import authentication from './authenticationReducer';
import alerts from './alertsReducer';
import locations from './locationsReducer';
import steps from './stepsReducer';
import {reducer as toastrReducer} from 'react-redux-toastr';
import customer from './customerReducer';
import quotation from './quotationReducer';
import orders from './ordersReducer';
import organizations from './organizationReducer';
import tags from './tagReducer';
import application from './applicationReducer';
import tagCategories from './tagCategoryReducer';
import postcodes from './postcodeReducer';
import postcodeCategories from './postcodeCategoryReducer';
import discountProduct from './discountProductReducer';
import admin from './adminReducer';
import access from './accessReducer';
import connections from './connectionsReducer';
import discount from './discountReducer';
import ordering from './orderingReducer';
import hostedVoice from './hostedVoiceReducer';
import loading from './loadingReducer';
import adminMenu from './adminMenuReducer';
import hardware from './hardwareReducer';
import partnerServices from './partnerServicesReducer';
import getQuotationOptionsReducer from './isdnLocationReducer/getQuotationOptionsReducer';
import mobile from './mobileReducer';
import getLocationOptionsReducer from './isdnLocationReducer/getLocationOptionsReducer';
import wordDocuments from "./wordDocumentsReducer";
import downloads from "./downloadsReducer";
import digitalSigningGroups from "./digitalSigningGroupsReducer";
import digitalSigningFields from "./digitalSigningFieldReducer";
import digitalSigningLog from "./digitalSigningLogReducer";
import digitalSigningDetails from "./digitalSigningDetailsReducer";
import apiStatus from "./apiStatusReducer";
import telephony from "./telephonyReducer";
import warnings from "./warningsReducer";
import dataManagement from "./dataManagementReducer";
import orderWizard from "./orderWizardReducer";
import products from "./productsReducer";
import organizationProviderLogo from './organizationProviderLogoReducer';
import termsAndConditions from './termsAndConditionsReducer';
import attachments from './attachmentsReducer';
import quotationDocuments from "./quotationDocumentReducer";
import signedDocument from "./signedDocumentReducer";
import adminOrders from './adminOrdersReducer';
import messages from './messagesReducer';

export default {
    authentication,
    alerts,
    locations,
    steps,
    toastr: toastrReducer,
    customer,
    quotation,
    orders,
    organizations,
    tags,
    tagCategories,
    postcodes,
    postcodeCategories,
    discountProduct,
    admin,
    access,
    connections,
    discount,
    hostedVoice,
    loading,
    adminMenu,
    hardware,
    partnerServices,
    quotationOptions: getQuotationOptionsReducer,
    locationOptions: getLocationOptionsReducer,
    mobile,
    ordering,
    wordDocuments,
    downloads,
    digitalSigningGroups,
    digitalSigningFields,
    digitalSigningLog,
    digitalSigningDetails,
    apiStatus,
    telephony,
    warnings,
    application,
    dataManagement,
    orderWizard,
    organizationProviderLogo,
    products,
    termsAndConditions,
    attachments,
    quotationDocuments,
    signedDocument,
    adminOrders,
    messages
};
