import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Input} from 'react-materialize'

import infoActions from "../../actions/workflow/infoStepActions";
import {odidoProviders} from "../../constants/providerType";
import DatePicker from "../../components/datePicker";

const inputPlaceholdersMapping = new Map([
    ["commercialContact", "Commercial"],
    ["technicalContact", "Technical"],
    ["projectManager", "Project manager"],
    ["financialContact", "Financieel contactpersoon"]
  ]
);

class ContactInfo extends Component {
  getTechnicalContactButtons = () => {
    let {checked, useTechnicalContactForOrder, onSameDataFunc, updateQuotationProperty} = this.props;

    return (
      <div className="technical-person-btn" style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column'}}>
        <div>
          <input
            name="datacopy"
            className="input-field"
            checked={checked}
            id="same_data_copy"
            type="checkbox"
            onChange={(e) => onSameDataFunc(e.target.checked)}
          />
          <label htmlFor="same_data_copy" style={{fontSize: "15px", fontStyle: "italic"}}>
            Gelijk aan commercieel contactpersoon
          </label>
        </div>
        <div>
          <input
            name="useTechnicalContactForOrder"
            className="input-field"
            checked={useTechnicalContactForOrder || false}
            id="useTechnicalContactForOrder"
            type="checkbox"
            onChange={(e) => updateQuotationProperty(e)}
          />
          <label htmlFor="useTechnicalContactForOrder" style={{fontSize: "15px", fontStyle: "italic"}}>
            Technisch contactpersoon gebruiken voor het order process
          </label>
        </div>
      </div>
    );
  };

  getProjectManagerButtons = () => {
    let {checked, onSameDataFunc} = this.props;

    return (
      <div className="project-manager-person-btn">
        <input
          name="datacopy-technical"
          className="input-field"
          checked={checked}
          id="same_data_copy_technical"
          type="checkbox"
          onChange={(e) => onSameDataFunc(e.target.checked)}
        />
        <label htmlFor="same_data_copy_technical" style={{fontSize: "15px", fontStyle: "italic"}}>
          Gelijk aan technisch contactpersoon.
        </label>
      </div>
    );
  };

  onChangeBirthDate = (date) => {
    const syntheticEvent = {
      target: {
        name: this.props.typeOfCustomer + ".birthdate",
        value: date
      }
    };

    this.props.onChangeFunc(syntheticEvent);
  };

  isReadOnly = (field) => {
    const {config, hasConnectWiseSync} = this.props;
    if (!hasConnectWiseSync) {
      return false;
    }

    const {readOnlyFields} = config;

    if (!readOnlyFields) {
      return false;
    }

    if (!readOnlyFields.length) {
      return false;
    }

    return readOnlyFields.includes(field);
  };

  render() {
    const {
      typeOfCustomer,
      sectionHeader,
      config,
      checked,
      required,
      onChangeFunc,
      quotation
    } = this.props;

    const nameLabel = sectionHeader === 'factuurgegevens' ? 'Factuur contactpersoon' : 'Naam';
    const emailLabel = sectionHeader === 'factuurgegevens' ? 'Factuur e-mail' : 'E-mail';

    const isOdidoPortfolio = odidoProviders.includes(quotation.provider);
    const isCommercialContact = typeOfCustomer === 'commercialContact';
    const isOdidoCommercialContact = isOdidoPortfolio && isCommercialContact;

    let button;
    let additionalClasses = '';
    let hideInput = false;

    if (typeOfCustomer === 'technicalContact') {
      button = this.getTechnicalContactButtons();
      additionalClasses = "technical-person";
      hideInput = checked;
    }

    if (typeOfCustomer === 'projectManager') {
      button = this.getProjectManagerButtons();
      additionalClasses = "project-manager-person";
      hideInput = checked;
    }

    return (
      <div>
        <div className={additionalClasses}>
          <div className="line tussenkop section-header">{sectionHeader}</div>
          {button}
        </div>
        <div className={'row'}>
          <div className="input-field col s12 m9 l9" style={{display: (hideInput ? 'none' : 'inline-block')}}>
            <input
              type="text"
              name={typeOfCustomer + '.fullName'}
              ref={typeOfCustomer + '.fullName'}
              value={config.fullName}
              onChange={onChangeFunc}
              className="validate"
              required={required}
              readOnly={this.isReadOnly('name')}
            />
            <label htmlFor="commercialContactFullName">{nameLabel}{required ? ' *' : ''}</label>
          </div>

          {!hideInput &&
          <Input
            ref={typeOfCustomer + '.title'}
            name={typeOfCustomer + '.title'}
            id={typeOfCustomer + '.title'}
            onChange={onChangeFunc}
            type="select"
            multiple={false}
            required={required}
            value={config.title}
            label={'Aanhef' + (required ? ' *' : '')}
            s={12}
            m={3}
            l={3}
            className="validate"
            disabled={this.isReadOnly('salutation')}
          >
            <option value=""/>
            <option value="Dhr.">Dhr.</option>
            <option value="Mevr.">Mevr.</option>
          </Input>
          }
        </div>

        {sectionHeader === 'factuurgegevens' &&
        <div>
          <div className={'row'}>
            <div className="input-field col s12 m8">
              <input
                ref={typeOfCustomer + '.street'}
                type="text"
                name={typeOfCustomer + '.street'}
                value={config.street}
                maxLength="18"
                className="validate"
                required={required}
                onChange={onChangeFunc}
              />
              <label htmlFor="financialContactStreet">Straat{required ? ' *' : ''}</label>
            </div>

            <div className="input-field col s4 m2">
              <input
                type="number"
                ref={typeOfCustomer + '.houseNumber'}
                name={typeOfCustomer + '.houseNumber'}
                value={config.houseNumber}
                onChange={onChangeFunc}
                className="validate"
                pattern="[0-9]"
                required={required}
              />
              <label htmlFor="financialContactHouseNumber">huisnr.</label>
            </div>

            <div className="input-field col s4 m2">
              <input
                type="text"
                ref={typeOfCustomer + '.houseNumberExtension'}
                name={typeOfCustomer + '.houseNumberExtension'}
                minLength="0"
                maxLength="20"
                value={config.houseNumberExtension}
                onChange={onChangeFunc}
                required={required}
              />
              <label htmlFor="financialContactHouseNumberExtension">toev.</label>
            </div>
          </div>

          <div className={'row'}>
            <div className="input-field col s4 m2">
              <input
                type="text"
                ref={typeOfCustomer + '.postalCode'}
                name={typeOfCustomer + '.postalCode'}
                value={config.postalCode}
                onChange={onChangeFunc}
                className="validate"
                required={required}
              />
              <label htmlFor="financialContactPostalCode">Postcode</label>
            </div>

            <div className="input-field col s12 m10">
              <input
                type="text"
                ref={typeOfCustomer + '.city'}
                name={typeOfCustomer + '.city'}
                value={config.city}
                onChange={onChangeFunc}
                className="validate"
                required={required}
              />
              <label htmlFor="financialContactCity">Plaats</label>
            </div>
          </div>
        </div>
        }

        <div className={'row'}>
          <div className={"input-field col s12 m6"} style={{display: (hideInput ? 'none' : 'inline-block')}}>
            <input
              type="email"
              ref={typeOfCustomer + '.email'}
              name={typeOfCustomer + '.email'}
              value={config.email}
              onChange={onChangeFunc}
              className={typeOfCustomer === 'commercialContact' ? 'invalid' : 'validate'}
              pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,9}$"
              required={required}
              readOnly={this.isReadOnly('email')}
            />
            <label htmlFor="commercialContactEmail">{emailLabel}{required ? ' *' : ''}</label>
          </div>

          {sectionHeader === 'factuurgegevens' &&
          <div className={'row'}>
            <div className="input-field col s12 m6">
              <input
                ref="iban"
                type="text"
                name="iban"
                value={this.props.ibanValue}
                maxLength="18"
                className="validate"
                onChange={this.props.handleChange}
              />
              <label htmlFor="iban">IBAN</label>
            </div>
          </div>
          }

          {sectionHeader === 'factuurgegevens' &&
          <div className={'row'}>
            <div className="input-field col s12 m6">
              <input
                ref="btwNumber"
                type="text"
                name="btwNumber"
                value={this.props.btwNumber}
                className="validate"
                onChange={this.props.handleChange}
              />
              <label htmlFor="btwNumber">BTW nummer</label>
            </div>
            <div className="input-field col s12 m6">
              <input
                ref="bicCode"
                type="text"
                name="bicCode"
                value={this.props.bicCode}
                className="validate"
                onChange={this.props.handleChange}
              />
              <label htmlFor="bicCode">BIC code</label>
            </div>
          </div>
          }

          {sectionHeader !== 'factuurgegevens' &&
          <div className={isOdidoPortfolio && isCommercialContact ? "input-field col s6 m3" : "input-field col s12 m6"}
               style={{display: (hideInput ? 'none' : 'inline-block')}}>
            {this.props.telephoneNumberLimit ?
              (<input
                type="tel"
                name={typeOfCustomer + '.phoneNumber'}
                ref={typeOfCustomer + '.phoneNumber'}
                value={config.phoneNumber}
                onChange={onChangeFunc}
                className="validate"
                minLength="10"
                maxLength="10"
                pattern="^\d{10}"
                required={required}
                readOnly={this.isReadOnly('phone')}
              />) :
              (<input
                type="tel"
                name={typeOfCustomer + '.phoneNumber'}
                ref={typeOfCustomer + '.phoneNumber'}
                value={config.phoneNumber}
                onChange={onChangeFunc}
                className="validate"
                minLength="10"
                pattern="^\d+"
                required={required}
                readOnly={this.isReadOnly('phone')}
              />)
            }
            <label htmlFor="commercialContactPhone">Telefoonnummer {required ? ' *' : ''}</label>
          </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({customer, quotation}) => {
  return {
    customer,
    quotation: quotation.currentQuotation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    infoActions: bindActionCreators(infoActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(ContactInfo);
