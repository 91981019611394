export default {
  KPN: 0,
  SALESVALUE: 1,
  VODAFONE: 2,
  DBIT: 3,
  ODIDO: 4,
  FLASH: 5,
  TELE2: 6,
  PHC_MOBILE:7,
  VENECO_MOBILE:8,

  LEAD: 10,

  KPN_PROVIDER_NAME: 'KPN ÉÉN',
  VODAFONE_PROVIDER_NAME: 'Vodafone',
  ODIDO_PROVIDER_NAME: 'Odido',
  FLASH_PROVIDER_NAME: 'Flash',
  TELE2_PROVIDER_NAME: 'Tele2',
  PHC_MOBILE_PROVIDER_NAME: 'PHC',
  VENECO_MOBILE_PROVIDER_NAME: 'Venéco',
}

export const odidoProviders = [4, 7, 8];