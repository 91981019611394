export default {
  logo: {
    maxFileSize: 2097152,
    allowedFileTypes: ['image/png'],
  },
  invoiceLogo: {
    maxFileSize: 2097152,
    allowedFileTypes: ['image/png', 'image/jpeg'],
  },
  background: {
    // maxFileSize: 2097152,
    maxFileSize: 10485760,
    allowedFileTypes: ['image/png'],
  },
  providersList: [
    {id: 0, name: 'KPN'},
    // {id: 1, name: 'Sales Value'},
    {id: 2, name: 'Vodafone'},
    {id: 3, name: 'Diensten Offerte'},
    {id: 4, name: 'Odido'},
    {id: 5, name: 'Flash'},
    {id: 6, name: 'Tele2'},
    {id: 7, name: 'PHC'},
    {id: 8, name: 'Venéco'},
  ]
};
