import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import { toastr } from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const uploadBulk = (file, providerId, options, noList) => {
    return async (dispatch) => {
        try {
            dispatch(toggleImportLoading());
            let url = apiRoutes.partnerServicesRelated.partnerServicesBulk.replace(/{providerId}/, providerId);
            url = url.replace(/{inactive}/, options.setToInactive);
            url = url.replace(/{copy}/, options.copyProduct);
            const response = await ApiService.performRequest(RequestType.POST, url, file);
            if (!noList) {
                dispatch(getAllPartnerServicesProducts(providerId));
            } else {
                if (!response.success) {
                    toastr.error('Bulk upload error', '');
                }
                return response;
            }
        }
        catch (error) {
            return {error}
        } finally {
          dispatch(toggleImportLoading());
        }
    }
};

const toggleImportLoading = () => ({
    type: actionTypes.partnerServicesToggleImportLoading
});

const getAllPartnerServicesProducts = (providerId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.partnerServicesRelated.getAllPartnerServices.replace('{providerId}', providerId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(setPartnerServicesProducts(response));
    } catch (error) {
      return {error};
    }
  }
};

const removePartnerServices = (providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.removePartnerServices;
            let response = await ApiService.performRequest(RequestType.DELETE, url);
            dispatch(getAllPartnerServicesProducts(providerId));
        } catch (error) {
            return {error};
        }
    }
};

const removePartnerService = (serviceId, providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.removePartnerService.replace('{serviceId}', serviceId);
            let response = await ApiService.performRequest(RequestType.DELETE, url);

            dispatch(getAllPartnerServicesProducts(providerId));
        } catch (error) {
            return {error};
        }
    }
};

const setPartnerServicesProducts = (products) => {
  return {
    type: actionTypes.setPartnerServicesProducts,
    payload: products
  }
};

const getPartnerServicesOptions = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.partnerServicesRelated.getPartnerServicesOptions.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(setPartnerServicesOptions(response));
    } catch (error) {
      return {error};
    }
  }
};

const setPartnerServicesOptions = (options) => {
  return {
    type: actionTypes.setPartnerServicesOptions,
    payload: options
  }
};

const getITServicesOptions = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.partnerServicesRelated.getITServicesOptions.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(setITServicesOptions(response));
    } catch (error) {
      return {error};
    }
  }
};

const persistPartnerServicesSelections = (selections, quotationId, type) => {
  return async () => {
    try {
      let url = apiRoutes.partnerServicesRelated.persistPartnerITServicesSelections.replace(/{type}/, type);
      await ApiService.performRequest(RequestType.POST, url, {selections, quotationId});
    } catch (error) {
      return {error};
    }
  }
};

const setITServicesOptions = (options) => {
  return {
    type: actionTypes.setITServicesOptions,
    payload: options
  }
};

const setPartnerServiceSelection = (selections) => {
  return {
    type: actionTypes.setPartnerServiceSelections,
    payload: selections
  }
};

const setITServiceSelection = (selections) => {
  return {
    type: actionTypes.setITServiceSelections,
    payload: selections
  }
};

const getPartnerServicesOptionsForQuotation = (quotationId, type) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.partnerServicesRelated.getPartnerServicesOptionsForQuotation
          .replace(/{quotationId}/, quotationId)
          .replace(/{type}/, type);
      let response = await ApiService.performRequest(RequestType.GET, url);

      const selections = [];

      response.options.map(selection => {
        if (type === 'it' || type === 'partner') {
          selections.push({
            id: selection.partnerItServiceId ? selection.partnerItServiceId : selection.productId,
            quantity: selection.quantity,
            category: selection.partnerItServiceCategory ? selection.partnerItServiceCategory : (['141A00001B', '151A00001B', '151A00002B', '151A00011B', 'ZTEXTRAAFS'].indexOf(selection.productNumber) > -1 ? 'MKB' : selection.type === 'acronis-it-service' ? 'Acronis' : 'KPN')
          });
        } else {
          selections.push({
            id: selection.hardware_option.id,
            quantity: selection.quantity,
            category: selection.hardware_option.category
          });
        }
      });

      if (!selections.length) {
        selections.push({id: '', quantity: 1, category: ''});
      }

      if (type === 'it') {
        dispatch(setITServiceSelection(selections));
      } else {
        dispatch(setPartnerServiceSelection(selections));
      }

      return selections;
    } catch (error) {
      return {error};
    }
  }
};

const getExampleFile = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.getImportXls;
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setExampleFile(requestData));
        }
        catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const getAssortimentFile = (providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.getAssortimentXls.replace('{providerId}', providerId);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setAssortimentFile(requestData));
        }
        catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const _setExampleFile = (data) => {
  return {
    type: actionTypes.partnerServicesDownloadExampleFile,
    payload: data
  };
};

const _setAssortimentFile = (data) => {
    return {
        type: actionTypes.partnerServicesDownloadAssortimentFile,
        payload: data
    };
};

const updateService = (serviceId, data, providerId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.updatePartnerService.replace(/{serviceId}/, serviceId);
            let requestData = await ApiService.performRequest(RequestType.POST, url, data);

            dispatch(getAllPartnerServicesProducts(providerId));
        } catch ({message}) {
            toastr.error('Kan de partner service niet bijwerken', message);
        }
    }
};

const patchService = (serviceId, data) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.patchPartnerService.replace(/{serviceId}/, serviceId);
            await ApiService.performRequest(RequestType.PATCH, url, data);
        } catch ({message}) {
            toastr.error('Kan de partner service niet bijwerken', message);
        }
    }
};

const duplicateService = (serviceId, keepRelations, keepActive) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.duplicatePartnerService.replace(/{serviceId}/, serviceId);
            url = url.replace(/{keepRelations}/, keepRelations);
            url = url.replace(/{keepActive}/, keepActive);
            await ApiService.performRequest(RequestType.POST, url, {});
        } catch ({message}) {
            toastr.error('Kan de partner service niet bijwerken', message);
        }
    }
};

const addPartnerService = (data, providerId, noList) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.addPartnerService.replace(/{providerId}/, providerId);
            let response = await  ApiService.performRequest(RequestType.POST, url, data);

            if (!noList) {
                dispatch(addPartnerServiceSuccess(response));
                dispatch(getAllPartnerServicesProducts(providerId));
            } else {
                return response;
            }
        } catch ({message}) {
            toastr.error('Kan de nieuwe partnerservice niet toevoegen: ', message);
        }
    }
};

const addPartnerServiceSuccess = (data) => {
    return {
        type: actionTypes.addPartnerServiceSuccess,
        payload: data
    };
};

const getProductsToLink = (providerId, quotationId = null) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.getAllProductsToLink.replace(/{providerId}/, providerId).replace(/{quotationId}/, quotationId);
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setProductsToLink(response));
        } catch (error) {
            return {error};
        }
    }
};

const setProductsToLink = (products) => {
    return {
        type: actionTypes.setProductsToLink,
        payload: products
    }
};

const getServiceOptionCategories = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.partnerServicesRelated.getServiceOptionCategories;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(setServiceOptionCategories(response));
        } catch (error) {
            return {error};
        }
    }
};

const setServiceOptionCategories = (products) => {
    return {
        type: actionTypes.setServiceOptionCategories,
        payload: products
    }
};

const getProductsForOverview = (queryParams, active, serviceProvider) => {
    return async (dispatch) => {
        try
        {
            let url = apiRoutes.partnerServicesRelated.productsOverview.replace('{active}', active);
            url = url.replace('{providerId}', serviceProvider) + (queryParams ? queryParams : '');
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(getProductsForOverviewSuccess(response));
            dispatch(savePaginationParameters(response));
        }
        catch (error)
        {
            toastr.error('Ophalen van products mislukt', error.message);
        }
    }
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
    return {
        type: actionTypes.savePagination,
        payload: {
            currentPage,
            resultsCount,
            pages
        }
    }
};

const getProductsForOverviewSuccess = ({products}) => {
    return {
        type: actionTypes.getProductsForOverview,
        payload: products
    }
};

const getProductTreeData = (productId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.partnerServicesRelated.treeData.replace('{productId}', productId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getProductTreeDataSuccess(response));
    } catch (error) {
      toastr.error('Ophalen van products mislukt', error.message);
    }
  }
};

const getProductTreeDataSuccess = (products) => {
  return {
    type: actionTypes.getProductTreeData,
    payload: products
  }
};

export default {
    getProductTreeData,
    uploadBulk,
    getExampleFile,
    getAssortimentFile,
    getAllPartnerServicesProducts,
    removePartnerServices,
    removePartnerService,
    setPartnerServicesProducts,
    getPartnerServicesOptions,
    setPartnerServicesOptions,
    getITServicesOptions,
    setITServicesOptions,
    setPartnerServiceSelection,
    setITServiceSelection,
    persistPartnerServicesSelections,
    getPartnerServicesOptionsForQuotation,
    addPartnerService,
    updateService,
    getProductsToLink,
    getServiceOptionCategories,
    getProductsForOverview,
    patchService,
    duplicateService
};
