import {isEmpty} from 'lodash';

export default class ObjectHelper {
    static checkIfAllValuesAreNotEmptyStringsOrUndefined(object) {

        if(!object || isEmpty(object))
            return false;

        let keys = Object.keys(object);

        for(let key of keys) {
            if(object[key] === '' || object[key] === undefined || object[key] === null )
                return false;
        }

        return true;
    }

    static removeUnderscoresFromPropertiesNames(input) {
        let keys = Object.keys(input);
        for(let key of keys) {
            if (key.includes('_'))
            {
                const keyWithoutUnderscore = key.replace('_', '');
                input[keyWithoutUnderscore] = input[key];
                delete input[key];
            }
        }

        return input;
    }

    static reversLocationsArrayApartFromFirstElement(input) {
        const globalLocation = input.find(x => x.id === -1);

        input.splice(0, 1);
        input.reverse();
        input.unshift(globalLocation);

        return input;
    };

    static sortByName(input) {
        input.sort( (firstElement, secondElement) => {
            if(firstElement.name < secondElement.name) return -1;
            if(firstElement.name > secondElement.name) return 1;
            return 0;
        });

        return input;
    };

    static fillGlobalLocationProperties(globalLocation, customer) {
        globalLocation.street = customer.street;
        globalLocation.city = customer.city;
        globalLocation.postal_code = customer.postalCode;
        globalLocation.house_number = customer.houseNumber;

        return globalLocation;
    };

    static enrichCustomerWithAdditionalProperties(customer, quotationId, contactDynamicsId) {
        return {
            id: customer.id,
            name: customer.name,
            street: customer.street,
            houseNumber: customer.house_number || customer.houseNumber,
            houseNumberExtension: customer.house_number_extension || customer.houseNumberExtension,
            postalCode: customer.postal_code || customer.postalCode,
            city: customer.city,
            kvk: customer.kvk,
            quotationID: quotationId,
            contactDynamicsId: contactDynamicsId,
            manually: customer.manually,
            apiId: customer.apiId,
            odidoBillingAccountCode: customer.odidoBillingAccountCode,
            connectWiseId: customer.connectWiseId,
            commercialContact: {
                title: customer.commercialContactSalutation ?? '',
                fullName: customer.commercialContactName ?? '',
                email: customer.commercialContactEmail ?? '',
                phoneNumber: customer.commercialContactPhone ?? ''
            },
            technicalContact: {
                title: '',
                fullName: '',
                email: '',
                phoneNumber: ''
            },
            projectManager: {
                title: '',
                fullName: '',
                email: '',
                phoneNumber: ''
            },
            financialContact: {
                title: '',
                fullName: '',
                email: '',
                phoneNumber: '',
                street: '',
                houseNumber: '',
                houseNumberExtension: '',
                postalCode: '',
                city: ''
            },
        };
    };
}
