import {apiRoutes} from "../../constants/apiRoutes";
import ApiService from "../../services/apiService";
import RequestType from "../../constants/requestType";
import {toastr} from "react-redux-toastr";
import {actionTypes} from "../../constants/actionTypes";
import _ from "lodash";

const getOrderingState = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderingRelated.get.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getOrderingStateSuccess(response));
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const getOrderingStateSuccess = (orderingState) => {
  return {
    type: actionTypes.getOrderingState,
    payload: orderingState
  };
};

const updateOrderingState = (quotationId, data) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderingRelated.update.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.PATCH, url, data);

      if (response.success === false) {
        throw response.message;
      } else {
        dispatch(updateOrderingStateSuccess(response));
      }
    } catch ({message}) {
      toastr.error('Backend-fout', message);
    }
  }
};

const updateOrderingStateSuccess = (orderingState) => {
  return {
    type: actionTypes.updateOrderingState,
    payload: orderingState
  };
};

const getSelectedProducts = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderingRelated.selectedProducts.replace('{quotationId}', quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getSelectedProductsSuccess(response));
      dispatch(setOrderContainVoipFlexibleNumberSuccess(response));
      return response;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const getOrdersContractDates = (quotationId) => {
  return async () => {
    try {
      let url = apiRoutes.orderingRelated.getOrdersContractDates.replace('{quotationId}', quotationId);
      return await ApiService.performRequest(RequestType.GET, url);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de einddatum van het contract op te vragen', '');
    }
  }
};

const getQuotationOrderXml = (quotation) => {
  return async () => {
    try {
      const url = apiRoutes.orderingRelated.getQuotationOrderXml.replace('{quotation}', quotation);
      const response = await ApiService.performRequest(RequestType.GET, url);

      return response;
    } catch ({message}) {
      toastr.error('Something went wrong getting the quotation order XML', '');
    }
  }
};

const getSelectedProductsSuccess = ({selectedProducts}) => {
  return {
    type: actionTypes.getSelectedProducts,
    payload: selectedProducts
  };
};

const setOrderContainVoipFlexibleNumberSuccess = ({orderContainVoipFlexiblePortingNumbers}) => {
  return {
    type: actionTypes.setOrderContainVoipFlexiblePortingNumbers,
    payload: orderContainVoipFlexiblePortingNumbers
  };
};

const updateOrderingProducts = (quotationId, data) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.orderingRelated.updateProducts.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.PATCH, url, data);

      if (response.success === false) {
        throw response.message;
      } else {
        dispatch(updateOrderingProductsSuccess(response));
      }
    } catch ({message}) {
      toastr.error('Backend-fout', message);
    }
  }
};

const updateOrderingProductsSuccess = (updateOrderingProducts) => {
  return {
    type: actionTypes.updateOrderingProducts,
    payload: updateOrderingProducts
  };
};

const sendOrder = (quotationId) => {
  return async (dispatch) => {
    try {
      dispatch(updateOrderingLoading(true));
      dispatch(updateOrderingMessage(''));
      let url = apiRoutes.orderingRelated.send.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.POST, url, []);

      if (response.success === false) {
        dispatch(updateOrderingMessage(response.message));
      } else {
        dispatch(updateOrderingStateSuccess(response.order));
        dispatch(updateOrderingMessage(response.message));
      }

      dispatch(updateOrderingLoading(false));
    } catch (error) {
      dispatch(updateOrderingLoading(false));
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Backend-fout', message);
    }
  }
};

const updateOrderingLoading = (loading) => {
  return {
    type: actionTypes.sendOrderLoading,
    loading
  }
}

const updateOrderingMessage = (message) => {
  return {
    type: actionTypes.updateOrderingStateMessage,
    message
  }
};

const getOrderingTelephoneNumber = (props) => {
  return async (dispatch) => {
    try {
      const {numberType, numberText, productId, quotation, action} = props;
      let url;

      switch (numberType) {
        case 'mobile':
          url = apiRoutes.orderingRelated.getMobileTelephoneNumber.replace(/{telephoneNumberId}/, productId);
          break;
        case 'ordering':
          url = apiRoutes.orderingRelated.getTelephoneNumberForBulk
            .replace(/{quotation}/, quotation)
            .replace(/{action}/, action);
          break;
        default:
          url = apiRoutes.orderingRelated.getFixedTelephoneNumber
            .replace(/{telephoneNumberId}/, productId)
            .replace(/{numberText}/, numberText);
      }

      let response = await ApiService.performRequest(RequestType.GET, url);

      if (action && response.success === false) {
        return response;
      }

      dispatch(getOrderingTelephoneNumberSuccess(response));
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const getOrderingTelephoneNumberSuccess = (telephoneNumber) => {
  return {
    type: actionTypes.getOrderingTelephoneNumber,
    payload: {
      ...telephoneNumber,
      contract_termination_date: telephoneNumber.contractEndDate,
      contract_termination_duration: telephoneNumber.contractLength
    }
  };
};

const getPortingDetails = (telephoneNumber) => {
  return async () => {
    try {
      let url = apiRoutes.orderingRelated.getPortingDetails.replace(/{telephoneNumberId}/, telephoneNumber);

      return await ApiService.performRequest(RequestType.GET, url);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const updatePortingDetails = (telephoneNumber, portingDetails) => {
  return async () => {
    try {
      let url = apiRoutes.orderingRelated.updatePortingDetails.replace(/{telephoneNumberId}/, telephoneNumber);

      return await ApiService.performRequest(RequestType.PATCH, url, portingDetails);
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const getVamoProductSelection = (quotation, vamoProduct) => {
  return async (dispatch) => {

    try {
      const url = apiRoutes.orderingRelated.getVamoProductSelection
        .replace(/{quotation}/, quotation)
        .replace(/{product}/, vamoProduct);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getVamoProductSelectionSuccess(response));
    } catch ({message}) {
      toastr.error('Something went wrong', message);
    }
  }
};

const getVamoProductSelectionSuccess = ({vamoProductSelection}) => {
  return {
    type: actionTypes.getVamoProductSelection,
    payload: vamoProductSelection
  };
};

const saveVamoProduct = (quotation, vamoProduct) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.saveVamoProductData.replace(/{quotation}/, quotation);
      const response = await ApiService.performRequest(RequestType.POST, url, vamoProduct);

      dispatch(saveVamoProductDataSuccess(response));
      return response;
    } catch ({message}) {
      toastr.error('Something went wrong', message);
    }
  }
};

const saveVamoProductDataSuccess = ({vamoProduct}) => {
  return {
    type: actionTypes.saveVamoProductData,
    payload: vamoProduct
  };
};

const saveOrderingTelephoneNumber = (quotationId, telephoneNumber) => {
  return async (dispatch) => {
    try {
      let url;
      let response;
      if (!telephoneNumber.id) {
        url = apiRoutes.orderingRelated.createTelephoneNumber.replace(/{quotationId}/, quotationId);
        response = await ApiService.performRequest(RequestType.POST, url, telephoneNumber);
      } else {
        url = apiRoutes.orderingRelated.updateTelephoneNumber.replace(/{telephoneNumberId}/, telephoneNumber.id);
        response = await ApiService.performRequest(RequestType.PATCH, url, telephoneNumber);
      }

      if (response.hasOwnProperty('success') && !response.success) {
        const message = response.message.join('. ') || '';
        toastr.error('Telefoonnummer is niet opgeslagen', message);
      } else {
        dispatch(saveOrderingTelephoneNumberSuccess(response));
        toastr.success('Telefoonnummer opgeslagen', '');
        return true;
      }
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const saveBulkTelephoneNumbers = (telephoneNumber) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.patchBulkTelephoneNumbers.replace(/{telephoneNumber}/, telephoneNumber.id);
      const response = await ApiService.performRequest(RequestType.PATCH, url, telephoneNumber);

      dispatch(saveOrderingTelephoneNumberSuccess(response));
      toastr.success('Telefoonnummer opgeslagen', '');
      return true;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk op de huidige stap op te halen', '');
    }
  }
};

const saveOrderingTelephoneNumberSuccess = (response) => {
  return {
    type: actionTypes.saveOrderingTelephoneNumber,
    payload: response
  };
};

const getHostedVoiceNumber = (hostedVoiceNumberId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.getHostedVoiceNumber.replace('{hostedVoiceNumberId}', hostedVoiceNumberId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getHostedVoiceNumberSuccess(response));

      return true;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om het nummer op te halen', message);
    }
  }
};

const getHostedVoiceNumberSuccess = (response) => {
  return {
    type: actionTypes.getOrderingHostedVoiceNumber,
    response
  }
};

const saveHostedVoiceNumber = (hostedVoiceNumberId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.getHostedVoiceNumber.replace('{hostedVoiceNumberId}', hostedVoiceNumberId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      //
      if (response.success) {
        dispatch(saveHostedVoiceNumberSuccess(response));
        toastr.success('Telefoonnummer opgeslagen');

        return true;
      } else {
        dispatch(saveHostedVoiceNumberFail(response));
      }
    } catch (message) {
      toastr.error('Telefoonnummer is niet opgeslagen');
    }
  }
};

const saveHostedVoiceNumberSuccess = (response) => {
  return {
    type: actionTypes.saveOrderingHostedVoiceNumber,
    hostedVoiceNumber: response.hostedVoiceNumber
  }
};

const saveHostedVoiceNumberFail = (response) => {
  return {
    type: actionTypes.saveOrderingHostedVoiceNumberFail,
    message: response.message
  }
};

const getAccessOptions = (selectionId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.getAccessOptions.replace('{selectionId}', selectionId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAccessOptionsSuccess(response));

      return true;
    } catch ({message}) {
      toastr.error('Het is niet mogelijk om de connectie opties op te halen', message);
    }
  }
};

const getAccessOptionsSuccess = (response) => {
  return {
    type: actionTypes.getOrderingAccessOptions,
    response
  }
};

const saveAccessOptions = (id, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.saveAccessOptions.replace('{optionsId}', id);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      //
      if (response.success) {
        dispatch(saveAccessOptionsSuccess(response));
        toastr.success('Connectie opties opgeslagen');

        return true;
      } else {
        dispatch(saveAccessOptionsFail(response));
      }
    } catch (message) {
      toastr.error('Connectie opties niet opgeslagen');
    }
  }
};

const saveAccessOptionsSuccess = (response) => {
  return {
    type: actionTypes.saveOrderingAccessOptionsSuccess,
    response
  }
};

const saveAccessOptionsFail = (response) => {
  return {
    type: actionTypes.saveOrderingAccessOptionsFail,
    message: response.message
  }
};

const saveMatchingAddons = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.orderingRelated.saveMatchingAddons.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(toggleSaveMatchingAddonsSuccess(response));
      toastr.success('Data successfully saved');
    } catch (error) {
      toastr.error('Failed to save data', error);
      return {error};
    }
  }
};

const toggleSaveMatchingAddonsSuccess = (response) => {
  return {
    type: actionTypes.saveMatchingAddons,
    payload: response
  }
};

const updateOrderingSentProductComment = (orderingSentProductId, value) => {
  return async () => {
    try {
      const url = apiRoutes.orderingRelated.saveOrderingProductComment.replace(/{orderingSentProductId}/, orderingSentProductId);
      const response = await ApiService.performRequest(RequestType.POST, url, {
        'comment': value,
      });
      if (response.success) {
        toastr.success('Data successfully saved');
      } else {
        toastr.error(response.message)
      }

    } catch ({message}) {
      toastr.error('Failed to save data', message)
    }
  }
}

const setOrderingXdslProductsOutletRequired = (quotationId, bulkParameters) => {
  return async () => {
    try {
      const url = apiRoutes.orderingRelated.setOrderingXdslProductsOutletRequired.replace(/{quotation}/, quotationId);
      let data = {};

      if (bulkParameters.isOutletRequiredDropDown && bulkParameters.selectedOutletRequiredValue !== -1) {
        data.bulkOutletRequiredValue = bulkParameters.selectedOutletRequiredValue;
      }

      if (bulkParameters.isPlacingBoxDropDown) {
        data.bulkPlacingBoxValue = bulkParameters.selectedPlacingBoxValue;
      }

      if (bulkParameters.isSkipTestingLabelingDropDown && bulkParameters.isSkipTestingLabelingValue !== -1) {
        data.bulkSkipTestingLabelingValue = bulkParameters.isSkipTestingLabelingValue;
      }

      let response = await ApiService.performRequest(RequestType.PUT, url, data)

      if (response.success) {
        toastr.success('Data successfully saved');

        return response;
      } else {
        toastr.error(response.message)
      }

    } catch ({message}) {
      toastr.error('Failed to save data', message);
    }
  }
};

const resendOrder = (orderId) => {
  return async () => {
    try {
      let url = apiRoutes.ordersRelated.resendOrder.replace(/{order}/, orderId);
      let response = await ApiService.performRequest(RequestType.POST, url, []);

      if (response && response.success) {
        toastr.success(response.message);
      }
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('Backend-fout', message);
    }
  }
};

const updateVamoOrder = (orderId, vamoProduct) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateVamoOrder.replace(/{order}/, orderId);
      return await ApiService.performRequest(RequestType.POST, url, vamoProduct);
    } catch ({message}) {
      toastr.error('Could not update the vamo order', message);
    }
  }
};

const updateVoiceNumberOrder = (orderId, data) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateVoiceNumberOrder.replace('{order}', orderId);
      return await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Could not update the hosted voice number order', message);
    }
  }
};

const updateAccessOrder = (orderId, data) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateAccessOrder.replace('{order}', orderId);
      return await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Could not update the access order', message);
    }
  }
};

const updateTelephoneNumberOrder = (orderId, telephoneNumber) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateTelephoneNumberOrder.replace('{order}', orderId);
      return await ApiService.performRequest(RequestType.PATCH, url, telephoneNumber);
    } catch ({message}) {
      toastr.error('Could not update the telephone order', message);
    }
  }
};

const updateUsernameOrder = (orderId, data) => {
  return async () => {
    try {
      const url = apiRoutes.ordersRelated.updateUsernameOrder.replace('{order}', orderId);
      return await ApiService.performRequest(RequestType.PATCH, url, data);
    } catch ({message}) {
      toastr.error('Could not update the telephone order', message);
    }
  }
};

export default {
  getOrderingState,
  getSelectedProducts,
  getOrdersContractDates,
  updateOrderingState,
  updateOrderingProducts,
  getOrderingTelephoneNumber,
  getPortingDetails,
  updatePortingDetails,
  getVamoProductSelection,
  saveVamoProduct,
  saveOrderingTelephoneNumber,
  getHostedVoiceNumber,
  saveHostedVoiceNumber,
  getAccessOptions,
  saveAccessOptions,
  sendOrder,
  saveMatchingAddons,
  getQuotationOrderXml,
  updateOrderingSentProductComment,
  saveBulkTelephoneNumbers,
  setOrderingXdslProductsOutletRequired,
  resendOrder,
  updateVamoOrder,
  updateAccessOrder,
  updateUsernameOrder,
  updateVoiceNumberOrder,
  updateTelephoneNumberOrder,
};
