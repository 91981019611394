import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {Input, Button, Preloader} from "react-materialize";
import debounce from 'lodash/debounce';

import DatePicker from "../../../components/datePicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import nl from 'date-fns/locale/nl';

import orderingActions from "../../../actions/workflow/orderingActions";
import quotationActions from "../../../actions/quotationActions";

import OrderingProducts from "./orderingProducts";
import TelephoneNumberDialog from "../../common/TelephoneNumberDialog";
import HostedVoiceNumberDialog from "../../common/HostedVoiceNumberDialog";
import AccessDialog from "../../common/AccessDialog";
import OrderingUserNamesDialog from "../../common/OrderingUserNamesDialog";
import LoaderComponent from '../../../containers/common/LoaderComponent';

import quotationType from '../../../constants/quotationType';
import { statusDataWithoutOrdering } from "../../../constants/quotationStatus";
import crmConnections from "../../../constants/crmConnections";
import statuses from "../../../constants/quotationStatus";
import productActionTypes from "../../../constants/productActionTypes";

import GeneralDialog from "../../../containers/common/GeneralDialog";
import FlexiblePortingNumbersDialog from "../../common/FlexiblePortingNumbersDialog";
import OrderXmlDialog from "../../common/OrderXmlDialog";
import VamoProductDialog from "../../common/VamoProductDialog";
import BulkDataDialog from "../../common/BulkDataDialog";
import telephoneNumberActions from "../../../constants/telephoneNumberType";
import {toastr} from "react-redux-toastr";
import {odidoProviders} from "../../../constants/providerType";

registerLocale('nl', nl);
setDefaultLocale('nl');

class OrderingStep extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modules: [],
      orderWishDate: new Date(),
      orderEeccDate: new Date(),
      companyName: this.props.customer.name,
      customerType: 'new',
      irmaCustomerNumber: '',
      telefooncentraleId: '',
      dialogOpened: false,
      vamoProductDialogOpened: false,
      loadingVamoProductDialog: false,
      selectedVamoProductId: null,
      hostedVoiceDialogOpened: false,
      accessDialogOpened: false,
      selectedTelephoneNumberId: false,
      selectedHostedVoiceNumberId: false,
      userNamesDialogOpened: false,
      userNames: {},
      checkedItems: new Map(),
      userNameSelected: null,
      selectedProduct: false,
      ordering_access_type: '',
      quotationStatus: '',
      selectedModule: null,
      infoModal: false,
      broadsoftException: false,
      existingVoip: false,
      flexiblePortingNumbersDialogOpened: false,
      orderXmlDialogOpened: false,
      orderEeccDateDialogOpened: false,
      bulkDialogOpened: false,
      selectedBulkAction: telephoneNumberActions.NEW_ACTION,
      isSelectedBulkAction: false,
      mobileOfferYearModal: false,
      isAccessWishDateDeletedModal: false,
      isOuletRequiredDropDown: false,
      selectedOutletRequiredValue: false,
      ordersEeccDates: [],
      selectedPlacingBoxValue: false,
      isPlacingBoxDropDown: false,
      isSkipTestingLabelingDropDown: false,
      selectedSkipTestingLabelValue: false
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEeccDateChange = this.handleEeccDateChange.bind(this);
  }

  componentDidMount() {
    let quotationId = this.props.quotation.id;
    const isVerlengenType = [quotationType.TYPE_EXISTING, quotationType.TYPE_VERLENGEN]
        .includes(this.props.quotation.type)

    const isNewQuotation = this.props.quotation.type === quotationType.TYPE_STANDARD

    this.props.orderingActions.getOrderingState(quotationId)
        .then(() => this.props.orderingActions.getSelectedProducts(quotationId))
        .then(() => this.props.orderingActions.getOrdersContractDates(quotationId)
          .then((response) => {
            if (response && response.orderContractEndDates.length) {
              this.setState({ordersEeccDates: response.orderContractEndDates});
            }
          })
        )
        .then(() => this.setState({loading: false}));

    if (this.props.ordering.order.count === 1) {
      this.setState({infoModal: true})
    }

    if ((isVerlengenType || isNewQuotation) && !this.props.customer.id) {
      this.props.quotationActions.addSoftAlert('LET OP: Klant info mist!');
    }

    this.saveDbData = debounce(this.saveDbData, 800);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    const isVerlengenType = [quotationType.TYPE_EXISTING, quotationType.TYPE_VERLENGEN]
        .includes(this.props.quotation.type)
    const isNewQuotation = this.props.quotation.type === quotationType.TYPE_STANDARD
    const order = this.props.ordering.order;
    const previousOrder = prevProps.ordering.order;

    if (previousOrder.order) {
      const irmaNumberCondition = !previousOrder.order.irmaCustomerNumber && order.irmaCustomerNumber !== previousOrder.order.irmaCustomerNumber;
      const verlengenCondition = isVerlengenType && order.customerType === 'existing';

      if (verlengenCondition && irmaNumberCondition) {
        this.props.quotationActions.removeSpecificSoftAlert('LET OP: Klant info mist!');
      }
    }

    if (isNewQuotation && this.props.customer.id) {
      this.props.quotationActions.removeSpecificSoftAlert('LET OP: Klant info mist!');
    }
  }

  componentWillReceiveProps(newProps) {
    const {ordering, quotation} = newProps;
    const {orderingTables, order} = ordering;
    let { mobileOfferYear } = quotation;

    this.setState({
      modules: orderingTables.data
    });

    if (order) {
      this.setState({
        orderWishDate: new Date(order.orderWishDate),
        orderEeccDate: new Date(order.orderEeccDate),
        companyName: order.companyName,
        customerType: order.customerType,
        irmaCustomerNumber: order.irmaCustomerNumber,
        telefooncentraleId: order.telefooncentraleId,
        groupId: order.groupId,
        domain: order.domain,
        reference: order.reference,
        userNames: order.userNames,
        quotationStatus: this.props.quotation.status,
        broadsoftException: order.broadsoftException,
        existingVoip: order.existingVoip
      });
    }

    this.state.modules.forEach((module) => {
      let size = 0;
      let disableProducts = 0;
      module.products.forEach((moduleProduct) => {
        if (module.isAccess) {

          if (module.accessProductsWishDateException) {
            this.setState({isAccessWishDateDeletedModal: true});
          }

          let foundProduct = ordering.selectedProducts.find(el =>
            el.productId === moduleProduct.id &&
            moduleProduct.orderId === el.orderId &&
            el.productLocationId === moduleProduct.locationId
          )

          if (foundProduct && typeof foundProduct.sent === 'boolean') {
            size++;

            if (foundProduct.sent) {
              disableProducts++;
            }
          }
        } else {
          ordering.selectedProducts.forEach(({productId, sent, telephoneNumber, indexVoip, hostedVoiceNumber}) => {
            if ((productId === moduleProduct.id || hostedVoiceNumber === moduleProduct.id) && typeof sent === 'boolean' && telephoneNumber === null && indexVoip === null) {
              size++;
              if (sent) {
                disableProducts++;
              }
            }
          })
        }
      });
      let selectedCategory = null;

      if (module?.isMobile) {
        if (!mobileOfferYear) {
          this.setState({mobileOfferYearModal: true});
        }

        let mobileProducts = module.products.filter(product => !product?.isVamoProduct);
  
        if (module.vamoProducts) {
          mobileProducts = [...mobileProducts, ...module.vamoProducts];
        }

        if (size === mobileProducts.length){
          selectedCategory = disableProducts === mobileProducts.length;
        }
      } else {
        if (size === module.products.length) {
          selectedCategory = disableProducts === module.products.length;
        }
      }

      this.setState({checkedItems: this.state.checkedItems.set(module.name, selectedCategory)})
    })
  };

  handleDateChange(date) {
    this.setState({orderWishDate: date});
    this.saveDbData(false);
  }

  handleEeccDateChange(date) {
    this.setState({orderEeccDate: date});
    this.saveDbData(false);
  }

  handleStatusChange = ({target}) => {
    const {value} = target;

    this.props.quotationActions.patchQuotation(this.props.quotation.id, { status: Number(value) }).then(() => {
      this.setState({
        quotationStatus: Number(value)
      });
    });
  }

  handleChange = ({target}) => {
    const {name, value} = target;
    this.setState({
      [name]: value
    });

    this.saveDbData(false);
  };

  saveDbData = () => {
    let quotationId = this.props.quotation.id;
    let data = (({orderWishDate, orderEeccDate, companyName, customerType, irmaCustomerNumber, telefooncentraleId, domain, reference, groupId}) => ({
      orderWishDate,
      orderEeccDate,
      companyName,
      customerType,
      irmaCustomerNumber,
      telefooncentraleId,
      domain,
      reference,
      groupId
    }))(this.state);
    data.quotation = quotationId;
    data.orderWishDate = data.orderWishDate.toISOString().split('T')[0];
    data.orderEeccDate = data.orderEeccDate.toISOString().split('T')[0];

    this.props.orderingActions.updateOrderingState(quotationId, data);
  };

  editNumber = (numberType, productId, numberText) => {
    this.setState({
      dialogOpened: true,
      numberText: numberText,
      numberType: numberType,
      loadingTelephoneDialog: true,
    });

    const parameters = {
      numberType,
      productId,
      numberText
    };

    this.props.orderingActions.getOrderingTelephoneNumber(parameters)
      .then(() => this.setState({
        selectedTelephoneNumberId: productId,
        loadingTelephoneDialog: false
      }));
  };
  
  editVamoProduct = (quotation, vamoProductId) => {
    this.setState({
      vamoProductDialogOpened: true,
      loadingVamoProductDialog: true,
    });
    
    this.props.orderingActions.getVamoProductSelection(quotation, vamoProductId)
      .then(() => this.setState({
        selectedVamoProductId: vamoProductId,
        loadingVamoProductDialog: false
      }));
  }
  
  cancelEditVamoProduct = () => {
    this.setState({
      vamoProductDialogOpened: false,
      selectedVamoProduct: null,
    });
  };

  cancelEditNumber = () => {
    this.setState({
      dialogOpened: false,
      selectedTelephoneNumberId: null
    });
  };

  saveNumber = (telephoneNumber) => {
    const quotationStatus = this.props.quotation.status;
    if (quotationStatus && ![statuses.STATUS_WON.value, statuses.STATUS_OPEN.value, statuses.STATUS_SEND.value].includes(quotationStatus)) {
      return;
    }

    let quotationId = this.props.quotation.id;

    if (telephoneNumber.action === productActionTypes.OPHEFFEN) {
      if (telephoneNumber.contract_termination_date) {
        telephoneNumber.contractEndDate = telephoneNumber.contract_termination_date;
      }
      if (telephoneNumber.contract_termination_duration) {
        telephoneNumber.contractLength = telephoneNumber.contract_termination_duration;
      }
    }

    if (this.state.isSelectedBulkAction) {
      this.props.orderingActions.saveBulkTelephoneNumbers(telephoneNumber).then((response) => {
        if (response) {
          this.setState({
            dialogOpened: false,
          });

          this.props.orderingActions.getOrderingState(this.props.quotation.id);
        }
      });
    } else {
      //save telephone number data
      this.props.orderingActions.saveOrderingTelephoneNumber(quotationId, telephoneNumber).then((response) => {
        if (response) {
          this.setState({
            dialogOpened: false,
            selectedTelephoneNumberId: null
          });

          this.props.orderingActions.getOrderingState(this.props.quotation.id);
        }
      });
    }
  };
  
  saveVamoProduct = (vamoProduct) => {
    const quotationStatus = this.props.quotation.status;
    if (quotationStatus && ![statuses.STATUS_WON.value, statuses.STATUS_OPEN.value, statuses.STATUS_SEND.value].includes(quotationStatus)) {
      return;
    }
    
    this.props.orderingActions.saveVamoProduct(this.props.quotation.id, vamoProduct)
      .then((response) => {
        if (response) {
          this.setState({
            vamoProductDialogOpened: false,
            selectedVamoProductId: null
          });
        }
    })
      .then(() => this.props.orderingActions.getOrderingState(this.props.quotation.id))
  }

  editAccessOptions = (selectionId, ordering_access_type) => {
    this.props.orderingActions.getAccessOptions(selectionId).then((response) => {
      if(response) {
        this.setState({
          accessDialogOpened: true,
          ordering_access_type
        });
      }
    });
  };

  cancelEditAccessOptions = () => {
    this.setState({
      accessDialogOpened: false
    });
  };

  saveAccessOptions = (data) => {
    const quotationStatus = this.props.quotation.status;
    if (quotationStatus && ![statuses.STATUS_WON.value, statuses.STATUS_OPEN.value, statuses.STATUS_SEND.value].includes(quotationStatus)) {
      return;
    }

    const newData = {...data};
    delete newData.id;

    this.props.orderingActions.saveAccessOptions(data.id, newData).then((response) => {
      if(response) {
        this.setState({
          accessDialogOpened: false
        });

        this.props.orderingActions.getOrderingState(this.props.quotation.id);
      }
    })
  };

  editHostedVoiceNumber = (productId) => {
    this.setState({
      loadingVoiceDialog: true
    });

    this.props.orderingActions.getHostedVoiceNumber(productId).then((response) => {
      if (response) {
        this.setState({
          hostedVoiceDialogOpened: true,
          loadingVoiceDialog: false,
          selectedHostedVoiceNumberId: productId
        });
      }
    })
  };

  saveHostedVoiceNumber = (data) => {
    const quotationStatus = this.props.quotation.status;
    if (quotationStatus && ![statuses.STATUS_WON.value, statuses.STATUS_OPEN.value, statuses.STATUS_SEND.value].includes(quotationStatus)) {
      return;
    }

    const newData = {...data};
    delete newData.id;
    delete newData.startingNumber;

    this.props.orderingActions.saveHostedVoiceNumber(data.id, newData).then((response) => {
      if (response) {
        this.setState({
          hostedVoiceDialogOpened: false,
          selectedHostedVoiceNumberId: null
        })

        this.props.orderingActions.getOrderingState(this.props.quotation.id);
      }
    });
  };

  cancelEditHostedVoiceNumber = () => {
    this.setState({
      hostedVoiceDialogOpened: false,
      selectedHostedVoiceNumberId: null
    })
  };

  hasCheckedProducts = () => {
    const { selectedProducts } =  this.props.ordering;
    const modules = this.state.modules;

    let checkedFounded = false;
    let filteredModules = modules.filter((row) => row.products.length);
    let products = new Map();

    selectedProducts.forEach(({productId, sent, telephoneNumber, indexVoip, hostedVoiceNumber, productLocationId}) => {
      let id = productId;

      if (telephoneNumber !== null) {
        id = telephoneNumber
      }

      if (hostedVoiceNumber !== null) {
        id = hostedVoiceNumber
      }

      if (indexVoip !== null) {
        id = productId + '_' + indexVoip.toString()
      }

      if (productLocationId) {
        products.set('product-' + productLocationId, typeof sent === 'boolean' ? sent : null);
      } else {
        products.set('product-' + id, typeof sent === 'boolean' ? sent : null)
      }
    });

    filteredModules.forEach((module) => {
      let moduleProducts = module.products;
      let checkedProducts = moduleProducts.filter((product) => {
        let productId = product.id;

        if (module.isUsername) {
          productId = module.userProducts[0]['id'];
        }
        if (module.isPhoneNumber) {
          productId = module.products[0]['id']
        }

        if (product?.locationId && typeof products.get('product-' + product.locationId) === 'boolean') {
          return true;
        }

        if (typeof products.get('product-' + productId) === 'boolean') {
          return true;
        }

        if (product.complex) {
          let complexSelected = Array.from(Array(product.amount).keys()).filter((i) => {
            const item = products.get('product-' + product.id + '_' + i);
            if (typeof item === 'boolean') {
              return true;
            }
          });

          if (complexSelected.length > 0) {
            return true;
          }
        }

        if (module.landlineUsers && module.landlineUsers.userProducts && !module.isUsername) {
          let usernameSelected = module.landlineUsers.userProducts.filter((el, index) => {
            const item = products.get('product-' + el.id + '_' + index);
            if (typeof item === 'boolean') {
              return true;
            }
          });

          if (usernameSelected.length > 0) {
            return true;
          }
        }

        if (module.mobileNumbers && module.mobileNumbers.products && !module.isPhoneNumber) {
          let mobileSelected = module.mobileNumbers.products.filter((el) => {
            if (el.product === productId) {
              const item = products.get('product-' + el.id);
              if (typeof item === 'boolean') {
                return true;
              }
            }
          });

          if (mobileSelected.length > 0) {
            return true;
          }
        }
      });

      if (checkedProducts.length > 0) {
        checkedFounded =  true;
      }
    });

    return checkedFounded;
  };

  isOrderButtonDisabled = () => {
    const { order, orderLoading } =  this.props.ordering;
    const { allowOrder, roles } =  this.props.authentication;
    const isVerlengenType = [quotationType.TYPE_EXISTING, quotationType.TYPE_VERLENGEN].includes(this.props.quotation.type)

    if (!allowOrder && roles.includes('ROLE_ADMIN')) {
      return true;
    }

    // Order doesn't have any products selected
    if (!this.hasCheckedProducts() || orderLoading === true || order.sentToGrexx === true) {
      return true;
    }

    if (!isVerlengenType) {
      if(!order.irmaCustomerNumber && order.customerType === 'existing') {
        return true;
      }

      if(order.customerType === 'existing') {
        const {orderingTables: {data}, selectedProducts} = this.props.ordering;
        const category = data.find((el) => el.name === "Telefooncentrale");
        if (category) {
          const product = category.products.find((el) => el.product_number === "028A00003N");
          if (product) {
            const telefoonSent = selectedProducts.find((el) => el.productId === product.id);
            if (telefoonSent && !order.telefooncentraleId && this.isOrderValid()) {
              return telefoonSent.sent;
            }
          }
        }
      }
    }

    // Order is not valid
    return !this.isOrderValid();
  };

  isOrderValid = () => {
    let isOrderValid = true;
    const {hostedVoiceOptions} = this.props.quotation;

    // Check if domain and group id are filled in
    if((hostedVoiceOptions.hostedVoiceActive || hostedVoiceOptions.businessConnectActive)) {
      if(!this.state.groupId || !this.state.domain) {
        return false;
      }
    }

    // Check if any product that was checked has the required data filled in
    this.state.modules.filter((row) => row.products.length &&
      (row.landlineUsers || row.mobileNumbers || row.isHostedVoiceNumber || row.isUsername || row.name === "Telefooncentrale")
    ).forEach(module => {
      if ((module.landlineUsers && module.landlineUsers.userProducts) || module.name === "Telefooncentrale") {
        let productIndex, currentProductId, moduleProducts;
        moduleProducts = module.name === 'Telefooncentrale' ? module.complexProducts : module.landlineUsers.userProducts;
        moduleProducts.forEach((product) => {
          if (product.id !== currentProductId) {
            currentProductId = product.id;
            productIndex = 0;
          }
          if (! this.selectionIsValid(product.id, product.required_data_filled, productIndex)) {
            isOrderValid = false;
          }

          productIndex++;
        });
      }

      if (module.mobileNumbers && module.mobileNumbers.products) {
        module.mobileNumbers.products.forEach((mobileNumber) => {
          if (! this.selectionIsValid(mobileNumber.product, mobileNumber.required_data_filled, null, mobileNumber.id)) {
            isOrderValid = false;
          }
        });
      }

      if (module.isHostedVoiceNumber) {
        module.products.forEach((hostedVoiceNumber) => {
          if (! this.selectionIsValid(hostedVoiceNumber.id, hostedVoiceNumber.required_data_filled, null, null, true)) {
            isOrderValid = false;
          }
        });
      }
    });

    return isOrderValid;
  };

  selectionIsValid = (productId, requiredDataFilled, index = null, telephoneNumber = null, isHostedVoiceNumber = false) => {
    const selectedProducts = this.props.ordering.selectedProducts;
    let selection = selectedProducts.find((product) => {
      if (isHostedVoiceNumber) {
        return product.hostedVoiceNumber === productId;
      }

      if (index !== null) {
        return product.productId === productId && product.indexVoip === index;
      }

      if (telephoneNumber !== null) {
        return product.productId === productId && product.telephoneNumber === telephoneNumber;
      }

      return product.productId === productId;
    });

    if (selection) {
      return !(selection.sent === false && !requiredDataFilled);
    }

    return true;
  };

  sendOrder = () => {
    const quotationId = this.props.quotation.id;
    const {orderContainVoipFlexiblePortingNumbers} = this.props.ordering;

    if (orderContainVoipFlexiblePortingNumbers) {
      this.setState({flexiblePortingNumbersDialogOpened: true});
      return;
    }

    this.props.orderingActions.sendOrder(quotationId)
      .then(() => this.props.orderingActions.getSelectedProducts(quotationId))
      .then(() => this.props.quotationActions.getQuotation(quotationId))
  };

  editUserNames = (index, selectedProduct = null, selectedModule = null) => {
    this.setState({
      userNamesDialogOpened: true,
      userNameSelected: index,
      selectedProduct,
      selectedModule
    })
  };

  openBulkDataDialog = () => {
    this.setState({
      bulkDialogOpened: true,
    })
  };

  onCancelBulkDataDialog = () => {
    this.setState({
      bulkDialogOpened: false
    })
  };

  onChangeAction = ({ target: { value } }) => {
    let parsedValue = parseInt(value);
    let state = {selectedBulkAction: parsedValue}

    if ([1, 4, 5].includes(parsedValue)) {
      state = {
        ...state,
        isOuletRequiredDropDown: false,
        isPlacingBoxDropDown: false,
        isSkipTestingLabelingDropDown: false
      };
    } else {
      state = {
        ...state,
        isOuletRequiredDropDown: parsedValue === 11,
        isPlacingBoxDropDown: parsedValue === 12,
        isSkipTestingLabelingDropDown: parsedValue === 11
      }
    }

    this.setState(state);
  };

  onSaveBulkAction = () => {
    const parameters = {
      numberType: 'ordering',
      quotation: this.props.quotation.id,
      action: this.state.selectedBulkAction,
    };

    if ([11, 12].includes(parameters.action)) {
      const bulkParameters = {
        selectedOutletRequiredValue: this.state.selectedOutletRequiredValue,
        isOutletRequiredDropDown: this.state.isOuletRequiredDropDown,
        selectedPlacingBoxValue: this.state.selectedPlacingBoxValue,
        isPlacingBoxDropDown: this.state.isPlacingBoxDropDown,
        isSkipTestingLabelingValue: this.state.selectedSkipTestingLabelValue,
        isSkipTestingLabelingDropDown: this.state.isSkipTestingLabelingDropDown
      }

      this.props.orderingActions.setOrderingXdslProductsOutletRequired(parameters.quotation, bulkParameters)
          .then((response) => {
            if (response.success) {
              this.setState({ bulkDialogOpened: false, selectedOutletRequiredValue: this.state.selectedOutletRequiredValue })
            }
          });
      return;
    }

    this.props.orderingActions.getOrderingTelephoneNumber(parameters)
      .then((response) => {
        if (response && !response.success) {
          this.setState({
            bulkDialogOpened: false,
          });

          toastr.error(response.message);
          return;
        }

        let numberText;
        switch (this.state.selectedBulkAction) {
          case 1:
            numberText = 'Porteren telefoon nummer';
            break;
          case 4:
            numberText = 'Simless porteren telefoon nummer';
            break;
          default:
            numberText = 'Nieuwe telefoon nummer';
        }

        this.setState({
          isSelectedBulkAction: true,
          bulkDialogOpened: false,
          dialogOpened: true,
          numberText: numberText
        });
      })
  };

  onCancelEditUserNames = () => {
    this.setState({
      userNamesDialogOpened: false,
      userNameSelected: null,
      selectedProduct: false
    })
  };

  closeOrderEeccDateDialog = () => {
    this.setState({orderEeccDateDialogOpened: false});
  };

  onCancelFlexiblePortingNumbersDialog = () => {
    this.setState({
      flexiblePortingNumbersDialogOpened: false
    })
  };

  onCancelOrderXmlDialog = () => {
    this.setState({
      orderXmlDialogOpened: false
    })
  };

  showOrderXml = () => {
    this.setState({loading: true});

    this.props.orderingActions.getQuotationOrderXml(this.props.quotation.id)
      .then((response) => {
        if (response) {
          this.setState({
            orderXML: response.payloads
          })
        }
      })
      .then(() => this.setState({loading: false, orderXmlDialogOpened: true}))
  };

  sendOrderFromFlexiblePortingDialog = () => {
    const quotationId = this.props.quotation.id;

    this.setState({flexiblePortingNumbersDialogOpened: false});

    this.props.orderingActions.sendOrder(quotationId)
      .then(() => this.props.orderingActions.getSelectedProducts(quotationId))
      .then(() => this.props.quotationActions.getQuotation(quotationId))
  };

  saveUserNames = (userNames) => {
    const data = {
      userNames,
      quotation: this.props.quotation.id
    };

    this.props.orderingActions.updateOrderingState(this.props.quotation.id, data).then(() => {
      this.props.orderingActions.getOrderingState(this.props.quotation.id);
      this.setState({
        userNamesDialogOpened: false
      });
    });
  };

  handleCheckboxChange = ({target: {name, checked}}) => {
    const newChecked = checked === true ? false : null;
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(name, newChecked) }));
    const module = this.state.modules.find((m) => m.name === name);
    let products = module.products;
    
    if (module?.isMobile) {
      products = products.filter(product => !product?.isVamoProduct);

      if (module.vamoProducts) {
        products = [...products, ...module.vamoProducts];
      }
    }
    
    products = products.filter((el) => {
      if (el.id === null) {
        return true;
      }

      const {useNina} = this.props.authentication;
      const isNotOrderingPossible = useNina ? el.nina_ordering_possible === false : el.grexx_ordering_possible === false;
      
      if (isNotOrderingPossible) {
        return false;
      }

      const p = this.props.ordering.selectedProducts.find((item) => item.productId === el.id);
      if (p) {
        return !p.sent
      }

      if (module.name === 'Mobiel' && p) {
        return p.telephoneNumber === el.id ? !p.sent : true
      }

      return true;
    });

    this.props.orderingActions.updateOrderingProducts(this.props.quotation.id, {products, checked: newChecked, module}).then(() =>
        this.props.orderingActions.getSelectedProducts(this.props.quotation.id)
    )
  };

  renderCustomerTypes = (isException) => {
    let options = isException ? ['existing'] : ['new', 'existing'];

    return options.map(el => <option value={el} key={el}>{el === 'new' ? 'nieuw' : 'bestaand'}</option>);
  };

  onOutletRequiredSelect = ({ target: { value } }) => {
    this.setState({ selectedOutletRequiredValue: parseInt(value) });
  }

  onPlacingBoxSelect = ({ target: { value } }) => {
    this.setState({ selectedPlacingBoxValue: parseInt(value) });
  }

  onSkipTestingLabelingSelect = ({ target: { value } }) => {
    this.setState({ selectedSkipTestingLabelValue: parseInt(value) });
  }

  renderEeccDateRecords = () => {
    return this.state.ordersEeccDates.map((eeccDate) => {
      return (
        <span>
          Gevonden contract einddatum: {eeccDate}
          <br/>
        </span>
      );
    });
  };

  render() {
    let { type, isCustomerOrderApi, inputsDisabled, status, mobileOfferYear } = this.props.quotation;
    let hasMobileData = false;

    const title = 'Orderen';
    const subTitle = null;
    const modules = this.state.modules;
    const filteredAddons = ['083A00006B', '083A00014B', '083A00015B', '083A00024B', '083A00032B', '083A00031B'];

    modules.map((module) => {
      if (module.name === 'Mobiel') {
        hasMobileData = true;
        module.products = module.products.filter((el) => !filteredAddons.includes(el.product_number));
      }

      return module;
    });

    const {hostedVoiceOptions, createdBy} = this.props.quotation;
    const {orderLoading, orderMessage, selectedAccessOptions, accessOptionsErrors, selectedProducts, order} = this.props.ordering;
    const usernameModule = modules.find(module => module.landlineUsers);
    const isWRUser = this.props.authentication.providers[0].organization.external_id;
    const isPIT = this.props.authentication.isPIT;
    const isException = isPIT && [quotationType.TYPE_VERLENGEN, quotationType.TYPE_EXISTING].includes(this.props.quotation.type);
    const { theme, isAdminFromDoceri, useNina, allowedUsersToNina } = this.props.authentication;
    const isQuotationCreatedByNinaUser = allowedUsersToNina.includes(createdBy);

    const isExistingType = this.props.quotation.type === quotationType.TYPE_EXISTING;
    const isVerlengenType = this.props.quotation.type === quotationType.TYPE_VERLENGEN;
    const isQuotationNewType = this.props.quotation.type === quotationType.TYPE_STANDARD;
    const shouldDisableCustomerFields = isVerlengenType || (isExistingType && isPIT) || (this.state.customerType === 'existing')
    const isExceptionQuotation = isVerlengenType || isExistingType;
    const isOdidoProvider = odidoProviders.includes(this.props.quotation.provider);

    const isConnectedToPipedrive = this.props.authentication.crmConnection === crmConnections.PIPEDRIVE;
    const hasStatusWon = status === statuses.STATUS_WON.value;
    const hasStatusSent = status === statuses.STATUS_SEND.value;
    const hasStatusOpen = status === statuses.STATUS_OPEN.value;
    const shouldDisableForPipedrive = isConnectedToPipedrive && !hasStatusWon;
    let publicSwitchOrderIdCondition = this.state.customerType !== 'new' && this.state.existingVoip;
    let mobileOfferCondition = hasMobileData && !mobileOfferYear;
    let orderingDisabled = inputsDisabled && !(hasStatusWon || hasStatusSent || hasStatusOpen);
    let isOrderingButtonDisabled = order.isOrderingButtonDisabled;

    return (
      <div className="ratiotable">
        {this.state.loading
          ? <LoaderComponent theme={theme} />
          : <fieldset style={{border: 'none', padding: 'unset', margin: 'unset', minWidth: 0}} disabled={orderingDisabled}>
            {(this.state.selectedTelephoneNumberId || this.state.isSelectedBulkAction) &&
              <TelephoneNumberDialog
                isExceptionQuotation={isExceptionQuotation}
                dialogOpened={this.state.dialogOpened}
                telephoneNumberId={this.state.selectedTelephoneNumberId}
                numberText={this.state.numberText}
                numberType={this.state.numberType}
                loading={this.state.loadingTelephoneDialog}
                onCancel={this.cancelEditNumber}
                onSave={this.saveNumber}
                inputsDisabled={orderingDisabled}
              />
            }

            {this.state.selectedHostedVoiceNumberId &&
              <HostedVoiceNumberDialog
                dialogOpened={this.state.hostedVoiceDialogOpened}
                hostedVoiceNumberId={this.state.selectedHostedVoiceNumberId}
                loading={this.state.loadingVoiceDialog}
                onCancel={this.cancelEditHostedVoiceNumber}
                onSubmit={this.saveHostedVoiceNumber}
                quotationType={type}
                inputsDisabled={orderingDisabled}
              />
            }

            <FlexiblePortingNumbersDialog
              dialogOpened={this.state.flexiblePortingNumbersDialogOpened}
              onCancelDialog={this.onCancelFlexiblePortingNumbersDialog}
              sendOrder={this.sendOrderFromFlexiblePortingDialog}
              inputsDisabled={orderingDisabled}
            />

            <OrderXmlDialog
              dialogOpened={this.state.orderXmlDialogOpened}
              ordersXmlString={this.state.orderXML}
              onCancelDialog={this.onCancelOrderXmlDialog}
              inputsDisabled={orderingDisabled}
              isOdidoProvider={isOdidoProvider}
            />

            <BulkDataDialog
              dialogOpened={this.state.bulkDialogOpened}
              onCancelDialog={this.onCancelBulkDataDialog}
              onChangeAction={this.onChangeAction}
              selectedBulkAction={this.state.selectedBulkAction}
              saveBulkAction={this.onSaveBulkAction}
              isOuletRequiredDropDown={this.state.isOuletRequiredDropDown}
              onOutletRequiredSelect={this.onOutletRequiredSelect}
              selectedOutletRequiredValue={this.state.selectedOutletRequiredValue}
              onPlacingBoxSelect={this.onPlacingBoxSelect}
              selectedPlacingBoxValue={this.state.selectedPlacingBoxValue}
              isPlacingBoxDropDown={this.state.isPlacingBoxDropDown}
              isSkipTestingLabelingDropDown={this.state.isSkipTestingLabelingDropDown}
              onSkipTestingLabelingSelect={this.onSkipTestingLabelingSelect}
              selectedSkipTestingLabelValue={this.state.selectedSkipTestingLabelValue}
            />

            <AccessDialog
              dialogOpened={this.state.accessDialogOpened}
              onCancel={this.cancelEditAccessOptions}
              accessOptions={selectedAccessOptions}
              onSubmit={this.saveAccessOptions}
              errors={accessOptionsErrors}
              orderingAccessType={this.state.ordering_access_type}
              quotationType={type}
              inputsDisabled={orderingDisabled}
              useNina={useNina}
            />

            <GeneralDialog
              dialogState={this.state.mobileOfferYearModal}
              modal={false}
              headerIcon="info"
              headerText="Controle vereist"
              closeHandler={() => this.setState({mobileOfferYearModal: !this.state.mobileOfferYearModal})}
              dialogBodyContent={(
                <div>
                  Er dient eerst een betreffende mobiele portfolio onder de tab ‘Mobiel’ geslecteerd te worden voordat de order verstuurd kan worden
                </div>
              )}
              rightButtonAction={() => this.setState({mobileOfferYearModal: !this.state.mobileOfferYearModal})}
              rightButtonText={'Begrepen'}
              />

            <GeneralDialog
              dialogState={this.state.infoModal && isVerlengenType}
              modal={false}
              headerIcon="info"
              headerText="Controle vereist"
              closeHandler={() => this.setState({infoModal: !this.state.infoModal})}
              dialogBodyContent={(
                <div>
                  Voor type verlengen en up/downgraden worden altijd de instellingen overgenomen zoals deze op de huidige order bekend zijn. <br/>
                  Deze kunnen niet tijdens de verlenging of up/downgraden aanvraag direct worden gewijzigd.
                  Raadpleeg de IRMA kennisdatabase om te zien wat er gebeurt met VAMO, Vast IP en Voordeelmodules.
                </div>
              )}
              rightButtonAction={() => this.setState({infoModal: !this.state.infoModal})}
              rightButtonText={'Begrepen'}
            />
            <GeneralDialog
                dialogState={this.state.isAccessWishDateDeletedModal}
                modal={false}
                headerIcon="info"
                closeHandler={() => this.setState({isAccessWishDateDeletedModal: !this.state.isAccessWishDateDeletedModal})}
                dialogBodyContent={(
                    <div>
                      De wensdata is/zijn inmiddels vertreken. Geef aub opnieuw wensdata op.
                    </div>
                )}
            />

            <GeneralDialog
              dialogState={this.state.orderEeccDateDialogOpened}
              modal={false}
              headerIcon="info"
              headerText="Huidige contract einddatum"
              closeHandler={this.closeOrderEeccDateDialog}
              dialogBodyContent={
                <div>
                  <span>Hierbij een overzicht van de bestaande contract einddatums die zijn gevonden binnen alle orders die een contract einddatum horen te hebben.</span>
                  <br/><br/>
                  {this.renderEeccDateRecords()}
                </div>
              }
              />

            <OrderingUserNamesDialog
              userNames={this.state.userNames}
              userNameSelected={this.state.userNameSelected}
              dialogOpened={this.state.userNamesDialogOpened}
              onCancel={this.onCancelEditUserNames}
              module={usernameModule ? usernameModule.landlineUsers : null}
              onSubmit={this.saveUserNames}
              selectedProduct={this.state.selectedProduct}
              isCustomerOrderApi={isCustomerOrderApi}
              order={this.props.ordering.order}
              quotationType={type}
              modules={modules}
              selectedModule={this.state.selectedModule}
              inputsDisabled={orderingDisabled}
            />
    
            {this.state.selectedVamoProductId && <VamoProductDialog
              dialogOpened={this.state.vamoProductDialogOpened}
              loading={this.state.loadingVamoProductDialog}
              onCancel={this.cancelEditVamoProduct}
              onSave={this.saveVamoProduct}
            />}

            <div id="Forms" className="paddingbottomnone">
              <div className="row without-margin-bottom">
                <div className="col s3">
                  <h1 className={'ratio-section-title'}>
                    <i className="small material-icons left">
                      add_shopping_cart
                    </i>
                    <span>{title}{subTitle ? `: ${subTitle}` : ''}</span>
                  </h1>
                </div>
                <div className="col s12">
                  <Input
                    type="text"
                    name="companyName"
                    label="Bedrijfsnaam"
                    style={{marginBottom: 0}}
                    onChange={this.handleChange}
                    value={this.state.companyName}
                    disabled={orderingDisabled}
                  />
                  <Input
                    type="select"
                    name="customerType"
                    label="Type klant"
                    className="nomarginbottom"
                    onChange={this.handleChange}
                    value={this.state.customerType}
                    disabled={orderingDisabled || shouldDisableCustomerFields}
                  >
                    {this.renderCustomerTypes(isException)}
                  </Input>

                  <Input
                    type="select"
                    name="status"
                    label="Status offerte"
                    className="nomarginbottom"
                    onChange={this.handleStatusChange}
                    value={this.state.quotationStatus}
                    disabled={orderingDisabled}
                  >
                    {statusDataWithoutOrdering.map((el) => (
                      <option value={el.value} key={el.value + el.label}>{el.label}</option>
                    ))}
                  </Input>

                  {
                    this.state.customerType !== 'new' &&
                    <Input
                      type="text"
                      name="irmaCustomerNumber"
                      label="Klantnummer IRMA"
                      style={{marginBottom: 0}}
                      onChange={this.handleChange}
                      value={this.state.irmaCustomerNumber}
                      disabled={shouldDisableCustomerFields || orderingDisabled}
                    />
                  }
                  <div className="col input-field">
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      className="nomarginbottom"
                      name="orderWishDate"
                      onChange={this.handleDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      selected={this.state.orderWishDate}
                      disabled={orderingDisabled}
                    />
                    <label>Order wensdatum</label>
                  </div>

                  { isVerlengenType &&
                  <div className="col input-field">
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      minDate={new Date()}
                      className="nomarginbottom"
                      name="orderEeccDate"
                      onChange={this.handleEeccDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      selected={this.state.orderEeccDate}
                      disabled={orderingDisabled}
                    />
                    <label>Einddatum contract telefoonnummers</label>
                  </div>
                  }
                  {isVerlengenType &&
                  <a style={{cursor: 'pointer'}} onClick={() => this.setState({orderEeccDateDialogOpened: true})}>
                    <i className="small material-icons left" style={{marginTop: '10px'}}>
                      info
                    </i>
                  </a>
                  }

                  {!(hostedVoiceOptions.hostedVoiceActive || hostedVoiceOptions.businessConnectActive) && isWRUser &&
                  <Input
                    type="text"
                    name="reference"
                    label="Interne referentie"
                    onChange={this.handleChange}
                    value={this.state.reference}
                    disabled={orderingDisabled}
                  />
                  }
                </div>

                {(hostedVoiceOptions.hostedVoiceActive || hostedVoiceOptions.businessConnectActive || this.state.broadsoftException) &&
                <div className="col s12">
                  <Input
                    type="text"
                    name="domain"
                    label="Broadsoft klant domein *"
                    onChange={this.handleChange}
                    value={this.state.domain}
                    disabled={orderingDisabled}
                  />
                  <Input
                    type="text"
                    name="groupId"
                    label="Broadsoft groep ID *"
                    onChange={this.handleChange}
                    value={this.state.groupId}
                    disabled={orderingDisabled}
                  />
                  {
                    publicSwitchOrderIdCondition &&
                    <Input
                      type="text"
                      name="telefooncentraleId"
                      label="Telefooncentrale OrderID"
                      style={{marginBottom: 0}}
                      onChange={this.handleChange}
                      value={this.state.telefooncentraleId}
                      disabled={this.state.customerType !== 'new' && isQuotationNewType}
                    />
                  }
                  {isWRUser &&
                  <Input
                    type="text"
                    name="reference"
                    label="Interne referentie (gaat niet nar IRMA)"
                    onChange={this.handleChange}
                    value={this.state.reference}
                    disabled={orderingDisabled}
                  />
                  }
                </div>
                }
              </div>
            </div>

            {modules.filter((row) => row.products.length).map((module, key) =>
              <OrderingProducts
                key={key}
                module={module}
                editNumber={this.editNumber}
                editVamoProduct={this.editVamoProduct}
                editHostedVoiceNumber={this.editHostedVoiceNumber}
                editAccessOptions={this.editAccessOptions}
                editUsernames={this.editUserNames}
                openBulkDialog={this.openBulkDataDialog}
                inputsDisabled={orderingDisabled}
                onCheckboxChange={this.handleCheckboxChange}
                checked={this.state.checkedItems.get(module.name)}
                selectedProducts={selectedProducts}
                updateProducts={this.props.orderingActions}
                quotation={this.props.quotation}
                userNames={this.state.userNames}
                statusSaveMatchingData={this.props.ordering.statusSaveMatchingData}
                isPIT={isPIT}
              />
            )}

            <div className="row">
              <div className="col s6">
                {isAdminFromDoceri && (isOdidoProvider || (!useNina && !isQuotationCreatedByNinaUser) )&&
                  <Button
                    className="ratio-btn-steps"
                    onClick={this.showOrderXml}
                  >
                    {isOdidoProvider ? 'Show order payload' : 'Show order XML'}
                  </Button>
                }
              </div>

              <div className="col s6" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  onClick={this.sendOrder}
                  disabled={this.isOrderButtonDisabled() || order.sentToGrexx || shouldDisableForPipedrive || orderingDisabled || mobileOfferCondition || isOrderingButtonDisabled}
                  className="ratio-btn-steps"
                >
                  Verstuur order
                  {orderLoading && <Preloader className="small"/>}
                </Button>
                <br/>
                {orderMessage}
              </div>
            </div>
          </fieldset>
        }
      </div>
    );
  }
}

const mapStateToProps = ({quotation, ordering, customer, authentication}) => {
  return {
    quotation: quotation.currentQuotation,
    ordering,
    authentication,
    customer: customer.selectedCustomer
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    orderingActions: bindActionCreators(orderingActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderingStep);
