import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from "material-ui";
import {connect} from "react-redux";
import {DelayInput} from "react-delay-input";
import {bindActionCreators} from "redux";
import discountActions from "../../../actions/workflow/discountActions";
import productType from "../../../constants/productType";
import quotationType from "../../../constants/quotationType";
import productActions from "../../../constants/productActionTypes";
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

class ModuleProducts extends Component {
  state = {
    selectedProduct: {},
  };

  generateHeaders = (isCustomCategory) => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn style={{width: `${isCustomCategory ? "15%" : "40%"}`}}>
            <div style={{whiteSpace: 'break-word', fontWeight: 'bold'}}>Productomschrijving</div>
          </TableHeaderColumn>

          <TableHeaderColumn style={{width: "10%"}}>
            <div style={{fontWeight: 'bold'}}>Gekozen order actie</div>
          </TableHeaderColumn>

          <TableHeaderColumn>
            <div style={{fontWeight: 'bold'}}>Aantal</div>
          </TableHeaderColumn>

          {isCustomCategory &&
            <TableHeaderColumn>
              <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
              <div className="subthread">maandelijks p/st</div>
            </TableHeaderColumn>
          }

          {isCustomCategory &&
            <TableHeaderColumn>
              <span style={{fontWeight: 'bold'}}>Bruto prijs</span>
              <div className="subthread">eenmalig p/st</div>
            </TableHeaderColumn>
          }

          {isCustomCategory &&
            <TableHeaderColumn>
              <span style={{fontWeight: 'bold'}}>Programmeren</span>
              <div className="subthread">eenmalig</div>
            </TableHeaderColumn>
          }

          {isCustomCategory &&
            <TableHeaderColumn>
              <span style={{fontWeight: 'bold'}}>Installeren</span>
              <div className="subthread">eenmalig</div>
            </TableHeaderColumn>
          }

          {isCustomCategory &&
            <TableHeaderColumn style={{position: 'unset'}}>
              <span style={{fontWeight: 'bold'}}>PM</span>
              <div className="subthread">eenmalig</div>
            </TableHeaderColumn>
          }

          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">maandelijks</div>
          </TableHeaderColumn>

          <TableHeaderColumn style={{position: 'unset'}}>
            <span style={{fontWeight: 'bold'}}>Totale prijs</span>
            <div className="subthread">eenmalig</div>
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
    )
  };

  calculateDiscountMonthly = (product, customMonthly = false) => {
    let productAmount = product.only_once_per_quotation && product.only_once_type === productType.TYPE_ONCE_ALL ? 1 : product.amount;
    if (!parseFloat(product.discount_gross_monthly)) {
      if (customMonthly) {
        return (customMonthly * product.discount_percentage_monthly) / 100;
      }
    }

    return productAmount * product.discount_gross_monthly;
  };

  calculateDiscountOnetime = (product, customOneTime = false) => {
    let productAmount = product.only_once_per_quotation &&
    [productType.TYPE_ONCE_ALL, productType.TYPE_ONCE_ONE_TIME].includes(product.only_once_type) ? 1 : product.amount;
    if (!parseFloat(product.discount_gross_onetime)) {
      if (customOneTime) {
        return (customOneTime * product.discount_percentage_onetime) / 100;
      }
    }

    return productAmount * product.discount_gross_onetime;
  };

  calculateTotalMonthlyPrice = (product, isCustomCategory, final = false) => {
    let productAmount = product.only_once_per_quotation && product.only_once_type === productType.TYPE_ONCE_ALL ? 1 : product.amount;
    if (!isCustomCategory) {
      return productAmount * product.discounted_price_monthly;
    } else {
      const monthlyTotal = productAmount *
          (product.price_gross_monthly);
      if (final) {
        return monthlyTotal - this.calculateDiscountMonthly(product, monthlyTotal);
      }
      return monthlyTotal;
    }
  };

  calculateTotalOneTimePrice = (product, isCustomCategory, final = false) => {
    let productAmount = product.only_once_per_quotation &&
    [productType.TYPE_ONCE_ALL, productType.TYPE_ONCE_ONE_TIME].includes(product.only_once_type) ? 1 : product.amount;
    if (!isCustomCategory) {
      return productAmount * product.discounted_price_onetime;
    } else {
      const oneTimeTotal = productAmount *
          (product.price_gross_onetime + product.setUpPrice + product.installationPrice + product.pmPrice);
      if (final) {
        return oneTimeTotal - this.calculateDiscountOnetime(product, oneTimeTotal);
      }
      return oneTimeTotal;
    }
  };

  getActionName = (action, isVerlengenType, fetchedFromGrexx) => {
    if (!action && isVerlengenType && !fetchedFromGrexx) {
      return 'Nieuw';
    }

    switch (action) {
      case 5:
        return 'Nieuw';
      case 1:
        return 'Porteren';
      case 4:
        return 'Simless porteren';
      case 6:
        return isVerlengenType ? 'Up/downgraden' : 'Upgraden';
      case 7:
        return 'Verlengen';
      case 8:
        return 'Opheffen';
      case 9:
        return 'Toevoeging';
      case 10:
        return 'Retentie';
      default:
        return 'Nieuw';
    }
  };

  onChange = (event, product) => {
    const {name, value} = event.target;
    const quotation = this.props.quotation.currentQuotation.id;

    const initialProduct = {
      id: product.id,
      name: product.name,
      sellingPricePerMonth: product.price_gross_monthly,
      sellingPriceOneOff: product.price_gross_onetime,
      setUpPrice: product.setUpPrice,
      installationPrice: product.installationPrice,
      pmPrice: product.pmPrice
    }

    const data = {
      ...initialProduct,
      [name]: value
    };

    this.props.discountActions.saveNewPrice(quotation, data)
      .then(() => this.props.discountActions.getDiscountState(quotation, false))
      .then(() => {
        this.setState(prevState => ({
          selectedProduct: {
            ...prevState.selectedProduct,
            productId: '',
            column: ''
          }
        }));
      });
  }

  onEditClick = (product, column) => {
    this.setState(prevState => ({
      selectedProduct: {
        ...prevState.selectedProduct,
        productId: product.id,
        column: column
      }
    }));
  }

  getOrderCount = (product) => {
    if (!product.orderList || !Object.keys(product.orderList).length) {
      return;
    }

    let orders = product.orderList;
    let orderCount = 0;

    Object.keys(orders).forEach((orderId) => {
      if (orderId) {
        orderCount++;
      } else {
        orderCount += orders[orderId];
      }
    });

    return ` (${orderCount} ${orderCount > 1 ? 'orders' : 'order'})`;
  }

  render() {
    const {module, isVerlengenType, isVodafoneQuotation} = this.props;
    const isExtensionPointsOn = this.props.authentication.providers[0] ? this.props.authentication.providers[0].organization.extension_points : false;
    const moduleName = module.name !== 'KPN IT Diensten' ? module.name : 'Microsoft IT Diensten';
    const extensionPoints = module.points;
    const {isSalesValue} = this.props.authentication;
    const {column, productId} = this.state.selectedProduct;

    return (
      <div className="row">
        <div className="col s12 overviewkopbg">
          <div className="overviewkop">
            {moduleName}

            {isExtensionPointsOn && !isVodafoneQuotation &&
              <span style={{marginLeft: '10px', fontSize: 'small'}}>Verbredingspunten: {extensionPoints > 0 ? '+' : ''}{extensionPoints}</span>
            }
          </div>

        </div>
        <RatioOverflowTableWrapper>
          <Table
              className="highlight without-margin-bottom"
              selectable={false}
              displayRowCheckbox={false}
          >
            {this.generateHeaders(module.isCustomCategory)}

            <TableBody displayRowCheckbox={false}>
              {module.products.map((product) =>
                  <TableRow key={product.id}>
                    <TableRowColumn style={{width: `${module.isCustomCategory ? "15%" : "40%"}`, whiteSpace: 'normal', wordWrap: 'break-word'}}>
                      {product.name}
                      {product.fetchedFromGrexx &&
                      <span style={{ color: 'red', fontStyle: 'italic' }}>
                      (LET OP: prijs onbekend)
                    </span>
                      }
                    </TableRowColumn>

                    <TableRowColumn style={{width: "10%", whiteSpace: 'normal', wordWrap: 'break-word'}}>
                      {this.getActionName(product.action, isVerlengenType, product.fetchedFromGrexx)}
                    </TableRowColumn>

                    <TableRowColumn>
                      {product.allow_decimal_quantity ? new Intl.NumberFormat('nl-NL', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(product.amount) : product.amount}

                      {product.only_once_per_quotation && product.only_once_type &&
                      <span>
                        {product.only_once_type === productType.TYPE_ONCE_ALL ?
                            ' (max 1 per offerte mogelijk)' : ' (eenmalige kosten 1x berekend)'
                        }
                      </span>
                      }
                      {
                        this.props.quotation.currentQuotation.type === quotationType.TYPE_VERLENGEN &&
                        product.amount > 1 &&
                        ![productActions.TOEVOEGING, productActions.NEW].includes(product.action) &&
                        <span> { this.getOrderCount(product)} </span>
                      }
                    </TableRowColumn>

                    {module.isCustomCategory &&
                    <TableRowColumn style={{textAlign: 'right'}}>
                      {!isSalesValue ?
                          <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.price_gross_monthly)}
                      </span> :

                          productId === product.id && column === 'priceGrossMonthly' ?
                              <DelayInput
                                  value={product.price_gross_monthly}
                                  type="number"
                                  name="sellingPricePerMonth"
                                  className="without-margin-bottom"
                                  delayTimeout={1000}
                                  min={0}
                                  style={{width: '50%'}}
                                  onChange={event => this.onChange(event, product)}
                              /> :
                              <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.price_gross_monthly)}

                                <i
                                    className={"tiny material-icons right green-text"}
                                    onClick={() => this.onEditClick(product, 'priceGrossMonthly')}
                                    style={{cursor: 'pointer'}}
                                >
                          edit
                          </i>
                        </span>
                      }
                    </TableRowColumn>
                    }

                    {module.isCustomCategory &&
                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {!isSalesValue ?
                          <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.price_gross_onetime)}
                      </span> :

                          productId === product.id && column === 'priceGrossOneTime' ?
                              <DelayInput
                                  value={product.price_gross_onetime}
                                  type="number"
                                  name="sellingPriceOneOff"
                                  className="without-margin-bottom"
                                  delayTimeout={1000}
                                  min={0}
                                  style={{width: '50%'}}
                                  onChange={event => this.onChange(event, product)}
                              /> :
                              <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.price_gross_onetime)}

                                <i
                                    className={"tiny material-icons right green-text"}
                                    onClick={() => this.onEditClick(product, 'priceGrossOneTime')}
                                    style={{cursor: 'pointer'}}
                                >
                            edit
                          </i>
                        </span>
                      }
                    </TableRowColumn>
                    }

                    {module.isCustomCategory &&
                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {!isSalesValue ?
                          <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.setUpPrice)}
                      </span> :

                          productId === product.id && column === 'setUpPrice' ?
                              <DelayInput
                                  value={product.setUpPrice}
                                  type="number"
                                  name="setUpPrice"
                                  className="without-margin-bottom"
                                  delayTimeout={1000}
                                  min={0}
                                  style={{width: '50%'}}
                                  onChange={event => this.onChange(event, product)}
                              /> :
                              <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.setUpPrice)}

                                <i
                                    className={"tiny material-icons right green-text"}
                                    onClick={() => this.onEditClick(product, 'setUpPrice')}
                                    style={{cursor: 'pointer'}}
                                >
                            edit
                          </i>
                        </span>
                      }
                    </TableRowColumn>
                    }

                    {module.isCustomCategory &&
                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {!isSalesValue ?
                          <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.installationPrice)}
                      </span> :

                          productId === product.id && column === 'installationPrice' ?
                              <DelayInput
                                  value={product.installationPrice}
                                  type="number"
                                  name="installationPrice"
                                  className="without-margin-bottom"
                                  delayTimeout={1000}
                                  min={0}
                                  style={{width: '50%'}}
                                  onChange={event => this.onChange(event, product)}
                              /> :
                              <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.installationPrice)}

                                <i
                                    className={"tiny material-icons right green-text"}
                                    onClick={() => this.onEditClick(product, 'installationPrice')}
                                    style={{cursor: 'pointer'}}
                                >
                            edit
                          </i>
                        </span>
                      }
                    </TableRowColumn>
                    }

                    {module.isCustomCategory &&
                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {!isSalesValue ?
                          <span>
                        {new Intl.NumberFormat('nl-NL', {
                          style: 'currency',
                          currency: 'EUR'
                        }).format(product.pmPrice)}
                      </span> :

                          productId === product.id && column === 'pmPrice' ?
                              <DelayInput
                                  value={product.pmPrice}
                                  type="number"
                                  name="pmPrice"
                                  className="without-margin-bottom"
                                  delayTimeout={1000}
                                  min={0}
                                  style={{width: '50%'}}
                                  onChange={event => this.onChange(event, product)}
                              /> :
                              <span>
                          {new Intl.NumberFormat('nl-NL', {
                            style: 'currency',
                            currency: 'EUR'
                          }).format(product.pmPrice)}

                                <i
                                    className={"tiny material-icons right green-text"}
                                    onClick={() => this.onEditClick(product, 'pmPrice')}
                                    style={{cursor: 'pointer'}}
                                >
                            edit
                          </i>
                        </span>
                      }
                    </TableRowColumn>
                    }

                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(this.calculateTotalMonthlyPrice(product, module.isCustomCategory, true))}
                    </TableRowColumn>

                    <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
                      {new Intl.NumberFormat('nl-NL', {
                        style: 'currency',
                        currency: 'EUR'
                      }).format(this.calculateTotalOneTimePrice(product, module.isCustomCategory, true))}
                    </TableRowColumn>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </RatioOverflowTableWrapper>
        <div className="line30" style={{ marginBottom: '10px' }}/>
      </div>
    )
  }
}

const mapStateToProps = ({quotation, discount, authentication}) => {
  return {
    quotation,
    discount,
    authentication
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discountActions: bindActionCreators(discountActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps,)(ModuleProducts);