import {actionTypes} from '../constants/actionTypes';
import {apiRoutes} from '../constants/apiRoutes';
import {toastr} from 'react-redux-toastr'
import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";
import infoStepActions from "./workflow/infoStepActions";
import accessActions from "./workflow/accessActions";
import locationActions from "./locationActions";

import _ from 'lodash'

const createNewQuotation = (type, akbRequired, provider, defaultQuotationName, quotationAccessPortfolio) => {
  return async (dispatch) => {
    try {
      const body = {
        customer: 0,
        type: type,
        akbRequired: akbRequired,
        name: defaultQuotationName ? defaultQuotationName : '',
        provider: provider ? provider : 0,
        accessPortfolio: quotationAccessPortfolio ? quotationAccessPortfolio : ''
      };

      dispatch(clearSelectedCustomer());

      const url = apiRoutes.quotationRelated.createNew;
      const requestData = await ApiService.performRequest(RequestType.POST, url, [body]);

      const {id, name} = requestData;

      dispatch(createNewQuotationSuccess(id, name));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk een nieuwe offerte aan te maken', message);
    }
  }
};

const duplicateQuotation = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.duplicateQuotation.replace(/{quotationId}/, quotationId);
      const requestData = await ApiService.performRequest(RequestType.POST, url);

      return requestData.quotation;
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk een nieuwe offerte aan te maken', message);
    }
  }
};

const clearSelectedCustomer = () => {
  return {
    type: actionTypes.clearSelectedCustomer
  };
};

const takeOverQuotation = (quotation) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.takeOver + quotation.id;
      const response = await ApiService.performRequest(RequestType.PUT, url);

      dispatch(getQuotationSuccess(response));

      if (response['previousUser'] !== '') {
        toastr.success('Offerte overgenomen van ' + response['previousUser']);
      } else {
        toastr.success('Offerte teruggegeven aan ' + response['quotation']['author']);
      }
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Can not offerte overnemen', message);
    }

  }
}

const setProjectManagerQuotation = (quotation) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.assignProjectManager + quotation.id;

      const response = await ApiService.performRequest(RequestType.PUT, url, {userToAssign: quotation.assignUser});

      dispatch(getQuotationSuccess(response));
      toastr.success('Offerte toegewezen aan ' + response['assignedUser']);
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Can not offerte overnemen', message);
    }

  }
}

const updateQuotation = (quotation, isLead = false) => {
  return async (dispatch) => {
    try {
      let url;
      if (isLead) {
        url = apiRoutes.leadRelated.updateLead + quotation.id;
        const response = await ApiService.performRequest(RequestType.PUT, url, [quotation]);

        dispatch(getQuotationSuccess(response));
        return response;
      }

      url = apiRoutes.quotationRelated.updateQuotation + quotation.id;
      quotation.flightCase = quotation.flight_case;
      quotation.flightCaseAmount = quotation.flight_case_amount;

      const response = await ApiService.performRequest(RequestType.PUT, url, [quotation]);

      dispatch(getQuotationSuccess(response));
      return response;
      if (!quotation.isIsdnMigration) {
        dispatch(accessActions.getAccessStepState(quotation.id));
      }
      dispatch(accessActions.getOptions(quotation.id));
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Offerte kan niet worden gewijzigd', message);
    }
  }
};

const getCustomerData = (quotation, opportunityNumber) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getCustomerData
          .replace(/{quotation}/, quotation)
          .replace(/{opportunityNumber}/, opportunityNumber);
      const response = await ApiService.performRequest(RequestType.GET, url);

      if (response && !response.success) {
        dispatch(setSalesValueMessage(response.message));
      }

      if (_.has(response, 'syncStatus')) {
        dispatch(setSyncStatus(response.syncStatus));
      }

      return response.customer;
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('', message);
    }
  }
};

const getQuotationAutotaskData = (quotation, opportunityId) => {
  return async () => {
    try {
      const url = apiRoutes.quotationRelated.getQuotationAutotaskData
        .replace(/{quotation}/, quotation)
        .replace(/{opportunityId}/, opportunityId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      return response.quotationData;
    } catch (error) {
      const message = _.has(error, 'response.data.message') ? error.response.data.message : error.message;
      toastr.error('', message);
    }
  }
};

const setSyncStatus = (syncStatus) => {
  return {
    type: actionTypes.setSyncStatusSuccess,
    payload: syncStatus
  };
};

const setSalesValueMessage = (message) => {
  return {
    type: actionTypes.setSalesValueMessage,
    payload: message
  };
};

const setQuotationId = (quotationId) => {
  return {
    type: actionTypes.setQuotationId,
    payload: quotationId
  };
};

const createNewQuotationSuccess = (id, name) => {
  return {
    type: actionTypes.createNewQuotation,
    payload: {
      id,
      name
    }
  }
};

export const getQuotation = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.get;
      const response = await ApiService.performRequest(RequestType.GET, url + '/' + quotationId);

      if (response.customer) {
        dispatch(infoStepActions.setSelectedCustomerInRedux(response.customer));
      }

      dispatch(getQuotationSuccess(response));
    } catch (error) {
      toastr.error('Offerte details kunnen niet worden opgehaald', error);
    }
  }
};

export const getQuotationChanges = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getChanges.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);
      dispatch(getChangesQuotationSuccess(response));
    } catch (error) {
      toastr.error('Offerte details kunnen niet worden opgehaald', error);
    }
  }
};

export const getPresetDiscountChanges = (quotationId) => {
  return async () => {
    try {
      const url = apiRoutes.quotationRelated.getPresetDiscountChanges.replace(/{quotationId}/, quotationId);

      return await ApiService.performRequest(RequestType.GET, url);
    } catch (error) {
      toastr.error('Offerte vooraf ingestelde kortingsdetails kunnen niet worden opgehaald.', error);
    }
  }
};

export const getPresetDiscountCheck = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getPresetDiscountCheck.replace(/{quotationId}/, quotationId);
      let response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getPresetDiscountCheckSuccess(response.success));
    } catch (error) {
      toastr.error('Offerte vooraf ingestelde kortingsdetails kunnen niet worden opgehaald.', error);
    }
  }
};

const getPresetDiscountCheckSuccess = (response) => {
  return {
    type: actionTypes.getPresetDiscountValidity,
    payload: response
  };
};

const resetPresetDiscountsWarnings = () => {
  return {
    type: actionTypes.getPresetDiscountValidity,
    payload: false
  };
};

const getQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.getQuotationSuccess,
    payload: quotation
  }
};

const getChangesQuotationSuccess = (changes) => {
  return {
    type: actionTypes.getQuotationChangesSuccess,
    payload: changes
  }
};


const getAllQuotations = (queryParams) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getAll + (queryParams ? queryParams : '');
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getAllQuotationsSuccess(requestData));
      dispatch(savePaginationParameters(requestData))
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk de lijst met offertes op te halen', message);
    }
  }
}

const getAllQuotationsSuccess = ({quotations}) => {
  return {
    type: actionTypes.getAllQuotations,
    payload: quotations
  };
};

const savePaginationParameters = ({currentPage, resultsCount, pages}) => {
  return {
    type: actionTypes.savePagination,
    payload: {
      currentPage,
      resultsCount,
      pages
    }
  }
}

const deleteQuotation = (quotation) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.deleteQuotation + '/' + quotation.id;
      const responseData = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(_deleteQuotationSuccess(quotation));

      toastr.success('Offerte verwijderd', '');
    }
    catch ({message}) {
      toastr.error('Offerte verwijderen niet mogelijk', message);
    }
  }
};

const patchQuotation = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.patchQuotation.replace('{quotationId}', quotationId);

      let requestData = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(_updateQuotationSuccess(requestData.quotation));
    }
    catch ({message}) {
      toastr.error('Kan de offerte niet updaten', message);
    }
  }
};

const _updateQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.updateQuotationSuccess,
    quotation: quotation
  }
};

const getQuotationLocationsFile = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getLocationsXls.replace(/{quotationId}/, quotationId);
      let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(_setQuotationLocationsFile(requestData));
    }
    catch ({message}) {
      toastr.error('Kan de XLS niet downloaden', message);
    }
  }
};

const _setQuotationLocationsFile = (data) => {
  return {
    type: actionTypes.getQuotationLocationsFile,
    payload: data
  };
};

const getXML = (quotation) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getXML + quotation.id;
      let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(_setXML(requestData));
    }
    catch ({message}) {
      toastr.error('Kan de XML niet downloaden', message);
    }
  }
};

const _setXML = (data) => {
  return {
    type: actionTypes.getXML,
    payload: data
  };
};

const getChecklist = (quotation, type) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getChecklist + quotation.id + '/' + type;
      let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(_setChecklist(requestData));
    }
    catch ({message}) {
      toastr.error('Kan checklist document niet downloaden', message);
    }
  }
};

const _setChecklist = (data) => {
  return {
    type: actionTypes.getChecklist,
    payload: data
  };
};

const getKwtXls = (quotation) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.get + '/' + quotation.id + '/kwt.xlsx';
      let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

      dispatch(_setKwtXls(requestData));
    }
    catch ({message}) {
      toastr.error('Kan KWT document niet downloaden', message);
    }
  }
};

const _setKwtXls = (data) => {
  return {
    type: actionTypes.getKwtXls,
    payload: data
  };
};

const _deleteQuotationSuccess = (quotation) => {
  return {
    type: actionTypes.deleteQuotation,
    payload: quotation.id
  }
};

const updateQuotationProperty = (target) => {
  let value = target.value;
  if (target.type === 'checkbox') {
    value = !!target.checked;
  }
  if (target.name === 'snumber') {
    value = `S${target.value}`;
  }
  if (target.name === 'contractDuration' || target.name === 'mobileContractDuration') {
    value = +target.value;
  }

  if(target.name === 'transferService' || target.name === 'transferServiceReason') {
    value = +target.value;
  }

  if (target.name === 'fortiGateActive') {
    value = target.value === 'true';
  }

  return {
    type: actionTypes.updateQuotationProperty,
    payload: {
      propertyToChange: target.name,
      newValue: value
    }
  }
};

const updateAsUnmangedAccess = (quotation) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.updateQuotation + quotation.id;

      quotation.flightCase = false;
      quotation.flightCaseAmount = 0;
      quotation.access_selection = 'unmanaged-access';

      const response = await ApiService.performRequest(RequestType.PUT, url, [quotation]);

      dispatch(getQuotationSuccess(response));
      dispatch(locationActions.setQuotationLocations(quotation.id));
    }
    catch (error) {
      const message = _.has(error, 'response.data.error.exception') ? error.response.data.error.exception[0].message : error.message;
      toastr.error('Offerte kan niet worden gewijzigd', message);
    }
  }
};

const refreshQuotationPrices = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.get + '/' + quotationId + '/refresh_prices';
      await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kan de prijzen niet vernieuwen. Probeer het opnieuw', message);
    }
  }
};

const refreshPresetDiscounts = (quotationId) => {
  return async () => {
    try {
      let url = apiRoutes.quotationRelated.get + '/' + quotationId + '/refresh_preset_discounts';
      await ApiService.performRequest(RequestType.GET, url);
    }
    catch ({message}) {
      toastr.error('Kan de netwerkacties niet vernieuwen.', message);
    }
  }
};

/**
 *
 * @param quotationId
 * @param file
 * @param fileType
 * @returns {Array}
 */
const bulkImport= (quotationId, locationId, file, fileType, existing) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.BulkImport.bulkImportPhoneNumbers.replace(/{quotationId}/, quotationId)
          .replace(/{locationId}/, locationId)
          .replace(/{existing}/, existing)
          .replace(/{fileType}/, fileType);
      const response = await ApiService.performRequest(RequestType.POST, url, file);
      if(fileType == 'mobile') {
        dispatch(bulkImportSuccess(response.telephoneNumbers));
      } else {
        dispatch(getQuotation(quotationId));
      }

      let { errorList, importedRows, isExcludedPortfolio, hasNoPortfolioSelected } = response;

      if((fileType == 'mobile' && Object.keys(errorList).length > 0) || errorList.length > 0) {
        return {
          errors: errorList,
          isExcludedPortfolio: isExcludedPortfolio,
          hasNoPortfolioSelected: hasNoPortfolioSelected
        };
      }

      if(importedRows > 0) {
        return {importedRows: importedRows}
      }

      toastr.success('Upload succesvol');
    } catch (error) {
      toastr.error('De bulk-upload is mislukt. Neem aub contact op met ons support team.', error);
    }
  }
};

const bulkImportSuccess = telephoneNumbers => {
  return {
    type: actionTypes.bulkImportSuccess,
    payload: telephoneNumbers
  }
};



const uploadAKB = (quotationId, file) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.uploadPhoneNumbers.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, file);

      dispatch(uploadAKBSuccess(response.telephoneNumbers));
      toastr.success('Upload succesvol');
    } catch (error) {
      toastr.error('AKB nummers uploaden mislukt. Neem aub contact op met ons support team.', error);
    }
  }
};

const uploadAKBSuccess = (telephoneNumbers) => {
  return {
    type: actionTypes.uploadAKBSuccess,
    payload: telephoneNumbers
  }
};

const getAKBTelephoneNumbers = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.getTelephoneNumbers.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(getTelephoneNumbersSuccess(response));
    } catch (error) {
      toastr.error('Failed to get AKB telephone numbers', error);
      return {error};
    }
  }
};

const getTelephoneNumbersSuccess = (response) => {
    return {
        type: actionTypes.getTelephoneNumbersSuccess,
        payload: response
    }
};

const saveAKBTelephoneNumber = (telephoneNumberId, data, quotationId, productId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.patchTelephoneNumber
          .replace(/{telephoneNumberId}/, telephoneNumberId)
          .replace(/{quotationId}/, quotationId)
          .replace(/{productId}/, productId);

      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(saveAKBTelephoneNumberSuccess(response.telephoneNumber));
    } catch (error) {
      toastr.error(_.has(error, 'response.data.message') ? error.response.data.message : 'Het opslaan van de telefoonnummer gegevens is mislukt. Neem aub contact op met ons support team.');
      return {error};
    }
  }
};

const addAKBTelephoneNumber = (quotationId, locationId, data) => {
  return async(dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.addTelephoneNumber.replace(/{quotationId}/, quotationId)
          .replace(/{locationId}/, locationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(saveAKBTelephoneNumberSuccess(response.telephoneNumber));
    } catch (error) {
      toastr.error('Het opslaan van de telefoonnummer gegevens is mislukt. Neem aub contact op met ons support team.', error);
      return {error};
    }
  }
}

const addTelephoneNumbersForEachProfile = (quotationId, locationId, data) => {
  return async(dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.addTelephoneNumbersForEachProfileSelected.replace(/{quotationId}/, quotationId)
          .replace(/{locationId}/, locationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);
      dispatch(saveTelephoneNumbersSuccess(response));
    } catch (error) {
      toastr.error('Het toevoegen van de telefoonnummer gegevens is mislukt. Neem aub contact op met ons support team.', error);
      return {error};
    }
  }
}

const saveTelephoneNumbersSuccess = (response) => {
  return {
    type: actionTypes.saveTelephoneNumbersSuccess,
    payload: response
  }
};

const deleteAKBTelephoneNumber = (telephoneNumber) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.deleteTelephoneNumber.replace(/{telephoneNumberId}/, telephoneNumber.id);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      dispatch(deleteAKBTelephoneNumberSuccess(telephoneNumber));
    } catch (error) {
      toastr.error('Het opslaan van de telefoonnummer gegevens is mislukt. Neem aub contact op met ons support team.', error);
      return {error};
    }
  }
};

const deleteAKBTelephoneNumberSuccess = (telephoneNumber) => {
  return {
    type: actionTypes.deleteAKBTelephoneNumberSuccess,
    payload: telephoneNumber
  }
};

const patchMobileSettings = (quotationId, telephoneNumber) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.patchMobileSettings.replace(/{quotationId}/, quotationId)
          .replace(/{telephoneNumberId}/, telephoneNumber);
      const response = await ApiService.performRequest(RequestType.PATCH, url);

      dispatch(patchMobileSettingsSuccess(response));
      toastr.success('Mobiele opties zijn gewijzigd.');
    } catch (error) {
      toastr.error('Het updaten van de mobiele opties is mislukt. Neem aub contact op met ons support team.', error);
      return {error};
    }
  }
};

const patchMobileSettingsSuccess = (response) => {
  return {
    type: actionTypes.patchMobileSettingsData,
    payload: response
  }
};

const saveAKBTelephoneNumberSuccess = (response) => {
  return {
    type: actionTypes.saveAKBTelephoneNumberSuccess,
    payload: response
  }
};

const updateAKBSelections = (data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.updateAKBSelections;
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      if (response.success) {
        //toastr.success('Numbers saved');
      }
    } catch (error) {
      toastr.error('Failed to save telephone numbers data', error);
      return {error};
    }
  }
};

const toggleFlagForAllAKBNumbers = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.toggleFlag.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(toggleFlagForAllAKBNumbersSuccess(response.telephoneNumbers));
    } catch (error) {
      toastr.error('Failed to save telephone numbers data', error);
      return {error};
    }
  }
};

const toggleFlagForAllAKBNumbersSuccess = (response) => {
  return {
    type: actionTypes.toggleFlagForAllAKBNumbersSuccess,
    payload: response
  }
};

const startOrderingQuotation = (quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.startOrdering.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PUT, url);

      if (response) {
        dispatch(_setOrderingSuccess(response.success));
      } else {
        toastr.error('Beginnen met bestellen is mislukt','');
      }
    } catch (error) {
      toastr.error('Beginnen met bestellen is mislukt','');
    }
  }
};

const _setOrderingSuccess = (response) => {
  return {
    type: actionTypes.setOrdering,
    payload: response
  }
};

const getDealSheet = (quotation) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.quotationRelated.getDealSheet.replace('{quotationId}', quotation.id);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);
            dispatch(_setDealSheet(requestData));
        } catch ({message}) {
            toastr.error('Kan excel document niet downloaden', message);
        }
    }
};

const _setDealSheet = (data) => {
    return {
        type: actionTypes.getDealSheet,
        payload: data
    };
};

const getMobileBulkExampleFile = (quotationId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.quotationRelated.getMobileBulkXls.replace('{quotationId}', quotationId);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setMobileBulkExampleFile(requestData));

            return true;
        } catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const _setMobileBulkExampleFile = (data) => {
    return {
        type: actionTypes.getMobileBulkExampleFile,
        data
    };
};

const getVoiceBulkExampleFile = (quotationId) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.quotationRelated.getVoiceBulkXls.replace('{quotationId}', quotationId);
            let requestData = await ApiService.performRequest(RequestType.GETFILE, url);

            dispatch(_setVoiceBulkExampleFile(requestData));

            return true;
        } catch ({message}) {
            toastr.error('Kan het voorbeeldbestand niet downloaden', message);
        }
    }
};

const _setVoiceBulkExampleFile = (data) => {
    return {
        type: actionTypes.getVoiceBulkExampleFile,
        data
    };
};

const deleteAllMobileOptions = (quotationId, locationId, fileType, deleteAllMobileOptions = null) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.mobileRelated.deleteAllMobileOptions.replace(/{quotationId}/, quotationId)
          .replace(/{locationId}/, locationId)
          .replace(/{fileType}/, fileType)
          .replace(/{deleteAllMobileOptions}/, deleteAllMobileOptions);
      const response = await ApiService.performRequest(RequestType.DELETE, url);

      if (fileType !== 'mobiele') {
        dispatch(getQuotation(quotationId));
      } else {
        dispatch(deleteAllMobileOptionsSuccess(response));
      }
      toastr.success('De opties zijn met sucess verwijderd');
    } catch (error) {
      toastr.error('Het verwijderen van de mobiele opties is mislukt. Neem aub contact op met ons support team.', error);
      return {error};
    }
  }
};

const deleteAllMobileOptionsSuccess = (quotationId) => {
  return {
    type: actionTypes.deleteAllMobileOptions,
    payload: quotationId
  }
};

const updatePhoneNumbers = (data, quotationId, object = false) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated;
      url = url.updateNumber.replace('{quotationId}', quotationId);

      const requestData = await ApiService.performRequest(RequestType.POST, url, data);

      if (requestData.message) {
        toastr.error('Er is iets misgegaan tijdens het updaten van de telefoonnummers. We konden de bestelinformatie van Grexx niet ophalen voor deze bestel-ID\'s: ', requestData.message.join("\r\n"));
      } else {dispatch(updatePhoneNumbersSuccess(requestData));
    }return requestData;
    } catch ({message}) {
      toastr.error('Er is iets misgegaan tijdens het updaten van de telefoonnummers', message);
      return false;
    }
  }
};

const updatePhoneNumbersSuccess = (data) => {
  return {
    type: actionTypes.updatePhoneNumber,
    payload: data
  }
};

const updatePhoneNumberActionStatus = (data) => {
  return {
    type: actionTypes.updatePhoneNumberActionStatus,
    payload: data
  };
};

const getPhoneNumbers = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getPhoneNumbers.replace('{quotationId}', quotationId);
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getPhoneNumbersSuccess(requestData));

      return requestData;
    } catch ({message}) {
      toastr.error('Kan het voorbeeldbestand niet downloaden', message);
    }
  }
};

const _getPhoneNumbersSuccess = (data) => {
  return {
    type: actionTypes.getPhoneNumbers,
    data
  };
};

const updateAccessProducts = (data, quotationId, object = false) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated;
      url = url.updateAccessProduct.replace('{quotationId}', quotationId);

      const requestData = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(updateAccessProductsSuccess(requestData));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk een nieuwe offerte aan te maken', message);
    }
  }
};

const updateAccessProductsSuccess = (data) => {
  return {
    type: actionTypes.updateAccessProducts,
    payload: data
  }
};

const getAccessProducts = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getAccessProducts.replace('{quotationId}', quotationId);
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getAccessProductsSuccess(requestData));

      return requestData;
    }
    catch ({message}) {
      toastr.error('Kan het voorbeeldbestand niet downloaden', message);
    }
  }
};

const _getAccessProductsSuccess = (data) => {
  return {
    type: actionTypes.getAccessProducts,
    data
  };
};

const updateVoiceProducts = (data, quotationId, object = false) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated;
      url = url.updateVoiceProduct.replace('{quotationId}', quotationId);

      const requestData = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(updateVoiceProductsSuccess(requestData));
    }
    catch ({message}) {
      toastr.error('Het is niet mogelijk een nieuwe offerte aan te maken', message);
    }
  }
};

const updateVoiceProductsSuccess = (data) => {
  return {
    type: actionTypes.updateVoiceProducts,
    payload: data
  }
};

const getVoiceProducts = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.getVoiceProducts.replace('{quotationId}', quotationId);
      let requestData = await ApiService.performRequest(RequestType.GET, url);

      dispatch(_getVoiceProductsSuccess(requestData));

      return requestData;
    }
    catch ({message}) {
      toastr.error('Kan het voorbeeldbestand niet downloaden', message);
    }
  }
};

const _getVoiceProductsSuccess = (data) => {
  return {
    type: actionTypes.getVoiceProducts,
    data
  };
};

const initializeQuotation = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.initializeQuotation.replace('{quotationId}', quotationId);
      await ApiService.performRequest(RequestType.GET, url);

      dispatch(getQuotation(quotationId));

      return true;
    } catch ({message}) {
      toastr.error('', message);
    }
  }
};

const sendDynamicsData = (quotationId) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.sendDynamicsData.replace('{quotationId}', quotationId);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(sendDynamicsDataSuccess(response));
      dispatch(getQuotation(quotationId));
    } catch ({message}) {
      toastr.error('', message);
    }
  }
};

const sendDynamicsDataSuccess = (response) => {
    return {
        type: actionTypes.sendDynamicsDataSuccess,
        payload: response
    }
};

const getUpgradingData = (quotationId, orderIds) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.getUpgradingData
        .replace(/{quotationId}/, quotationId);

      const response = await ApiService.performRequest(RequestType.POST, url, orderIds );

      if (response) {
        dispatch(retrieveUpgradingOptionsSuccess(response));
      }
    } catch (message) {
      toastr.error('Kan bestaande gegevens niet ophalen omdat ', message);
    }
  }
};

const retrieveUpgradingOptionsSuccess = (result) => {
  return {
    type: actionTypes.getUpgradingData,
    payload: result
  }
};

const saveAllAKBTelephoneNumber = (data, quotationId) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.AKBRelated.patchAllTelephoneNumbers.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.PATCH, url, data);

      dispatch(saveAllAKBTelephoneNumbersSuccess(response.telephoneNumbers));
    } catch (error) {
      toastr.error('Failed to save telephone numbers data', error);
      return {error};
    }
  }
};

const saveAllAKBTelephoneNumbersSuccess = (response) => {
  return {
    type: actionTypes.saveAKBTelephoneNumberSuccess,
    payload: response
  }
};

const updateDataRetrievalLoader = (type, value) => {
  return {
    type: type === 'mobile' ? actionTypes.processingExistingMobileData : actionTypes.processingExistingVoiceData,
    payload: value
  }
};

const sendEmailToCustomer = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.sendEmail.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(sendEmailToCustomerSuccess(response));

      if (response && response.success) {
        toastr.success('De e-mail is succesvol verzonden.', '');
      }

      return response;
    } catch ({message}) {
      toastr.error('Something went wrong. ', message);
    }
  }
};

const sendEmailToCustomerSuccess = (response) => {
  return {
    type: actionTypes.sendEmailToCustomerSuccess,
    payload: response
  }
};

const updateOverviewData = (quotationId, data) => {
  return async (dispatch) => {
    try {
      const url = apiRoutes.quotationRelated.updateOverviewData.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      dispatch(updateOverviewDataSuccess(response));
    } catch ({message}) {
      toastr.error('Something went wrong. ', message);
    }
  }
};

const updateOverviewDataSuccess = (response) => {
  return {
    type: actionTypes.updateOverviewData,
    payload: response
  }
};

const setCurrentSoftAlert = (alertMessage) => {
  return {
    type: actionTypes.setCurrentSoftAlert,
    payload: alertMessage
  }
};

const setCurrentHardAlert = (alertMessage) => {
  return {
    type: actionTypes.setCurrentHardAlert,
    payload: alertMessage
  }
};

const addSoftAlert = (alertMessage) => {
  return {
    type: actionTypes.addSoftAlert,
    payload: alertMessage
  }
};

const addHardAlert = (alertMessage) => {
  return {
    type: actionTypes.addHardAlert,
    payload: alertMessage
  }
};

const removeSpecificSoftAlert = (alertMessage) => {
  return {
    type: actionTypes.removeSpecificSoftAlert,
    payload: alertMessage
  }
};

const removeSpecificHardAlert = (alertMessage) => {
  return {
    type: actionTypes.removeSpecificHardAlert,
    payload: alertMessage
  }
};
const resetAlerts = () => {
  return {
    type: actionTypes.resetAlerts
  }
};
const resetSoftAlerts = () => {
  return {
    type: actionTypes.resetSoftAlerts
  }
};
const resetHardAlerts = () => {
  return {
    type: actionTypes.resetHardAlerts
  }
};

const removeLastSoftAlert = () => {
  return {
    type: actionTypes.removeLastSoftAlert
  }
};

const removeLastHardAlert = () => {
  return {
    type: actionTypes.removeLastHardAlert
  }
};

const setConnectionProductsToVerlengen = (quotation) => {
  return async () => {
    try {
      let url = apiRoutes.quotationRelated.setConnectionsToVerlengen.replace(/{quotation}/, quotation);
      const response = await ApiService.performRequest(RequestType.POST, url);

      toastr.success(`Alle bestaande access producten ${response.totalProducts} zijn geselecteerd voor verlenging.`, '');
    } catch ({message}) {
      toastr.error('Something went wrong. ', message);
    }
  }
}

const syncOpportunityEmail = (quotation) => {
  return async (dispatch) => {
    try {
      let url = apiRoutes.quotationRelated.syncOpportunityEmail.replace(/{quotation}/, quotation);
      const response = await ApiService.performRequest(RequestType.GET, url);

      dispatch(setIsSyncOpportunityEmailSuccess(response));
    } catch (error) {
      toastr.error('Something went wrong', 'Syncing the customer email failed.');
    }
  }
};

const setIsSyncOpportunityEmailSuccess = ({success}) => {
  return {
    type: actionTypes.setIsSyncOpportunityEmail,
    payload: success
  };
};

const checkQuestionsApproval = (quotationId, data) => {
  return async () => {
    try {
      const url = apiRoutes.quotationRelated.checkQuestionsApproval.replace(/{quotation}/, quotationId);
      await ApiService.performRequest(RequestType.POST, url, data);
    } catch ({message}) {
      toastr.error('Something went wrong. ', message);
    }
  }
};

const sendMandateEmail = (quotationId, data) => {
  return async () => {
    try {
      const url = apiRoutes.quotationRelated.sendMandateEmail.replace(/{quotationId}/, quotationId);
      const response = await ApiService.performRequest(RequestType.POST, url, data);

      if (response && response.success) {
        toastr.success('De e-mail is succesvol verzonden.', '');
      } else {
        toastr.error(response.message);
      }
    } catch (error) {
      const {response, message} = error;
      if (response && response.data && response.data.message) {
        toastr.error('Something went wrong. ', response.data.message);
      } else {
        toastr.error('Something went wrong. ', message);
      }
    }
  }
};

const saveQuotationDetails = (quotationId, data) => {
  return async () => {
    try {
      const url = apiRoutes.quotationRelated.saveQuotationDetails.replace(/{quotationId}/, quotationId);
      await ApiService.performRequest(RequestType.POST, url, data);
    } catch ({message}) {
      toastr.error('Something went wrong. ', message);
    }
  }
};

export default {
  getUpgradingData,
  updateVoiceProducts,
  getVoiceProducts,
  updateAccessProducts,
  getAccessProducts,
  createNewQuotation,
  duplicateQuotation,
  setQuotationId,
  getAllQuotations,
  deleteQuotation,
  updateQuotationProperty,
  updateAsUnmangedAccess,
  getQuotation,
  getQuotationChanges,
  getPresetDiscountChanges,
  getPresetDiscountCheck,
  resetPresetDiscountsWarnings,
  getQuotationLocationsFile,
  updateQuotation,
  getCustomerData,
  getQuotationAutotaskData,
  getXML,
  getChecklist,
  getQuotationSuccess,
  takeOverQuotation,
  getKwtXls,
  refreshQuotationPrices,
  refreshPresetDiscounts,
  uploadAKB,
  saveAKBTelephoneNumber,
  updateAKBSelections,
  toggleFlagForAllAKBNumbers,
  getAKBTelephoneNumbers,
  getTelephoneNumbersSuccess,
  patchQuotation,
  addAKBTelephoneNumber,
  addTelephoneNumbersForEachProfile,
  deleteAKBTelephoneNumber,
  patchMobileSettings,
  getDealSheet,
  bulkImport,
  bulkImportSuccess,
  startOrderingQuotation,
  getMobileBulkExampleFile,
  getVoiceBulkExampleFile,
  deleteAllMobileOptions,
  setProjectManagerQuotation,
  updatePhoneNumbers,
  getPhoneNumbers,
  initializeQuotation,
  sendDynamicsData,
  saveAllAKBTelephoneNumber,
  updateDataRetrievalLoader,
  sendEmailToCustomer,
  setCurrentSoftAlert,
  setCurrentHardAlert,
  addSoftAlert,
  addHardAlert,
  removeSpecificSoftAlert,
  removeSpecificHardAlert,
  resetAlerts,
  resetSoftAlerts,
  resetHardAlerts,
  removeLastSoftAlert,
  removeLastHardAlert,
  updateOverviewData,
  setConnectionProductsToVerlengen,
  syncOpportunityEmail,
  checkQuestionsApproval,
  updatePhoneNumberActionStatus,
  sendMandateEmail,
  saveQuotationDetails,
}
