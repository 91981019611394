export default {
  TYPE_STANDARD: 0,
  TYPE_MIGRATION: 1,
  TYPE_EXTENSION: 2,
  TYPE_EXPANSION: 3,
  TYPE_EXISTING: 4,
  TYPE_VERLENGEN: 5,
  TYPE_QA: 6,
  TYPE_KOOP: 7,
  TYPE_HUUR: 8,
  TYPE_VERGELIJKING: 9
}