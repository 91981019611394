import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import getQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/getQuotationOptionsThunk';
import updateQuotationOptionsThunk from '../../../actions/workflow/isdnMigrationActions/updateQuotationOptionsThunk';
import quotationActions from "../../../actions/quotationActions";
import partnerServicesActions from "../../../actions/partnerServicesActions";
import hardwareActions from "../../../actions/hardwareActions";

import QuotationQuestionModule from "../../common/QuotationQuestionsTable/QuotationQuestionModule";
import ITServiceOptionWrapper from '../../common/ITServiceOptionWrapper';
import ITKPNServiceOption from "../../common/ITKPNServiceOption";
import AddOneTimeProduct from "../../common/AddOneTimeProduct";
import ITDienstenMKBTVOption from "../../common/ITDienstenMKBTVOption";
import LoaderComponent from '../../../containers/common/LoaderComponent';

import providerType from "../../../constants/providerType";
import quotationType from "../../../constants/quotationType";
import HardwareDependencyHelper from "../../../helper/hardwareDependencyHelper";
import ITServicesAcronisOption from "../../common/ITServicesAcronisOption";

class ITServicesStep extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      productsToLinkLoaded: false,
    };
  }

  componentDidMount() {
    const { currentQuotation } = this.props.quotation;
    if (currentQuotation.type === quotationType.TYPE_QA) {
      this.setState({ loading: false });
      return;
    }

    this.props.hardwareActions.getAllCustomHardwareOptionsSelections(currentQuotation.id);
    this.props.partnerServicesActions.getITServicesOptions(currentQuotation.id);
    this.props.partnerServicesActions.getPartnerServicesOptionsForQuotation(currentQuotation.id, 'it');

    this.props.partnerServicesActions.getServiceOptionCategories();
    this.props.partnerServicesActions.getProductsToLink(currentQuotation.provider, currentQuotation.id).then(() => {
      this.setState({
        productsToLinkLoaded: true,
        loading: false
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { partnerServices, quotation } = this.props;
    const { itServicesSelections } = partnerServices;

    if (prevProps.partnerServices.itServicesSelections !== itServicesSelections) {
      this.props.hardwareActions.getAllCustomHardwareOptionsSelections(quotation.currentQuotation.id);
    }
  }

  render() {
    const { customHardwareSelections, partnerServices } = this.props;
    const { itServicesOptions } = partnerServices;
    let categories = [...new Set(itServicesOptions.map(option => option.category))];

    // Sort alphabetically
    categories = categories.sort();

    // Move overige dienst category to last
    if(categories.indexOf('Overige dienst') > -1) {
      categories = categories.filter(category => category !== 'Overige dienst');
      categories.push('Overige dienst')
    }

    const { theme, roles, oneTimePartnerService } = this.props.authentication;
    const { currentQuotation } = this.props.quotation;
    const canAddPartnerItProduct = roles.some(item => oneTimePartnerService.includes(item));
    const isQAQuotation = currentQuotation.type === quotationType.TYPE_QA;

    let alreadySelected = HardwareDependencyHelper.getAlreadySelectedHardware(customHardwareSelections, '', null).filter(selectionId => selectionId);
    const isSalesValue = this.props.authentication.isSalesValue;

    return (
      <div>
        {this.state.loading ? <LoaderComponent theme={theme} /> :
          (isQAQuotation ? <QuotationQuestionModule module={'it'} location={-1} /> :
            <div>
              { isSalesValue &&
              <div className="row" id="Forms" style={{padding: 20}}>
                {this.state.productsToLinkLoaded && <ITServicesAcronisOption />}
              </div>
              }

              {[providerType.KPN, providerType.SALESVALUE].includes(currentQuotation.provider) &&
              <div className="row" id="Forms" style={{padding: 20}}>
                {this.state.productsToLinkLoaded && <ITKPNServiceOption />}
              </div>
              }

              {[providerType.KPN, providerType.SALESVALUE].includes(currentQuotation.provider) &&
              <div className="row" id="Forms" style={{padding: 20}}>
                {this.state.productsToLinkLoaded && <ITDienstenMKBTVOption />}
              </div>
              }

              {categories.map(category => {
                const optionsForCategory = itServicesOptions.filter(option => option.category === category && HardwareDependencyHelper.hasRelationDependencyMet(option, alreadySelected));
                const allowDecimalQuantity = optionsForCategory[0].allow_decimal_quantity || false;

                return (
                  <div className="row" id="Forms" style={{padding: 20}}>
                    <ITServiceOptionWrapper
                      optionsList={optionsForCategory}
                      category={category}
                      allowDecimalQuantity={allowDecimalQuantity}
                    />
                  </div>
                )
              })}

            {canAddPartnerItProduct &&
              <div className="row" id="Forms" style={{padding: 20}}>
                <AddOneTimeProduct type={'it'}/>
              </div>
            }
          </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({ quotation, quotationOptions, partnerServices, authentication, hardware }) => {
  return {
    quotation,
    quotationOptions,
    partnerServices,
    authentication,
    customHardwareSelections: hardware.customHardwareSelections || [],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuotationOptions: bindActionCreators(getQuotationOptionsThunk, dispatch),
    updateQuotationOptions: bindActionCreators(updateQuotationOptionsThunk, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    partnerServicesActions: bindActionCreators(partnerServicesActions, dispatch),
    hardwareActions: bindActionCreators(hardwareActions, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ITServicesStep);
